import axios from 'axios';
import qs from 'qs';
import setAuth, { ApiKeyToken, AuthToken } from './setAuth';
import store from '../ReduxStore';
import { timeOutLogoutUser, Relogin } from '../ReduxActions/userActions';
import {
  convertStoreIdsToQueryParam,
  dig,
  stringToDate,
  equalNameFindSerialTotal
} from '../Helper/commons';
import { differenceInCalendarDays } from 'date-fns';
import config from '../config';
import { get_x_session_id } from './x_session_id_creator';
import { axiosBuilder } from '@udentify/uxios';
import { TimezoneOffsetInstance } from '../Utils/Time';
import { apiErrorFormat } from '../Utils/language';
import { lineTypes } from '../Constants';
// import setAuth, { AuthToken } from '../../Helper/setAuth';
// import config from '../../config';
const IsFile = 'is-file';
const IsPhoto = 'is-photo';

const resolveToken = loginResponse => {
  return loginResponse.data.access_token;
};
const RefreshTokenRequest = async _axios => {
  const Account = JSON.parse(localStorage.getItem('user') || '{}');
  let data = qs.stringify({
    grant_type: 'refresh_token',
    refresh_token: Account.refresh_token
  });

  try {
    const res = await _axios.post(config.apibase + '/Token', data);

    if (res) {
      localStorage.setItem(
        'user',
        JSON.stringify({
          ...Account,
          ...res.data
        })
      );
      setAuth(new AuthToken(res.data.access_token));
    }

    return res;
  } catch {
    localStorage.clear();
    window.location.reload();
  }
};

const SetAuthHeader = axiosInstance => method => {
  // setAuth(method());
};
export const AxiosInstance = axiosBuilder
  .initConfig({
    baseURL: config.apibase,
    WithCredentials: false,
    refreshTokenUrl: '/Token',
    resolveToken,
    RefreshTokenRequest,
    setAuthHeader: SetAuthHeader
  })
  .getInstance();

AxiosInstance.interceptors.response.use(
  function (response) {
    if (checkError(response)) {
      return Promise.reject(response.Message);
    }

    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const { dispatch } = store;

// AxiosInstance.defaults.baseURL = config.apibase;
if (localStorage.user) {
  const user = JSON.parse(localStorage.user);
  if (user.access_token) {
    setAuth(new AuthToken(user.access_token));
    dispatch({
      type: 'INITIALIZE_STATE'
    });
  } else {
    setAuth(new AuthToken());
  }
}

var timeZoneOffset = TimezoneOffsetInstance.offset;

function makeRequestCreator() {
  var RequestsCall = {};
  return function ({ url, options, requestName, cancelRuleParam }) {
    if (
      RequestsCall[requestName] &&
      Object.keys(RequestsCall[requestName]).length &&
      RequestsCall[requestName]
    ) {
      RequestsCall[requestName].abort();
    }
    if (!RequestsCall[requestName]) {
      RequestsCall[requestName] = {};
    }
    // if(!RequestsCall[requestName]){
    // RequestsCall[requestName] = []
    // }

    RequestsCall[requestName] = new AbortController();
    return AxiosInstance.get(url, {
      ...options,
      signal: RequestsCall[requestName].signal
    })
      .then(response => {
        return response;
      })
      .catch(function (thrown) {
        // if (AxiosInstance.isCancel(thrown)) {
        // } else {
        //   // handle error
        // }
        return Promise.reject(false);
      });
  };
}

const getRequest = makeRequestCreator();

function checkError(res) {
  if (
    typeof res === 'object' &&
    typeof res !== null &&
    'Success' in res &&
    res.Success === false &&
    'Message' in res &&
    typeof res.Message === 'string'
  ) {
    return res.Message;
  }

  return false;
}

export async function Login(username, password, captcha_value) {
  let data = qs.stringify({
    grant_type: 'password',
    username,
    password,
    captcha_value
  });
  const res = await AxiosInstance.post(`/Token`, data, {
    headers: {
      x_session_id: get_x_session_id()
    }
  });
  return Promise.resolve(res);
}
export async function PostRegister(data) {
  const res = await AxiosInstance.post(`/User/New`, data);
  return Promise.resolve(res);
}
export async function MailResetPasswordToken(email) {
  const res = await AxiosInstance.post(`/User/MailResetPasswordToken`, email, {
    headers: { 'Content-Type': 'text/plain' }
  });

  return Promise.resolve(res);
}
export async function ResetPasswordEmail(resetCode) {
  const res = await AxiosInstance.post(`/User/ResetPasswordEmail`, resetCode, {
    headers: { 'Content-Type': 'text/plain' }
  });

  return Promise.resolve(res);
}
export async function ResetPasswordRequest(config) {
  await AxiosInstance.post(`/User/ResetPassword`, config);
}

export async function UserDetails() {
  const res = await AxiosInstance.get(`/User/Details`);
  return Promise.resolve(res);
}

export async function UserPermissions() {
  const res = await AxiosInstance.get(`/User/Permissions`);
  return Promise.resolve(res);
}

export async function LeavePageLog(payload, token) {
  return await fetch(config.apibase + '/User/PageLog', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(payload),
    keepalive: true
  });
}

export async function PrivacyPolicyAccept() {
  await AxiosInstance.post(`/User/AcceptAgreement `);
}

function fixify(value, disableFixed) {
  if (disableFixed || typeof value !== 'number') return value;
  return parseFloat(value.toFixed(3));
}

function calculateSerialValue({
  oldValue,
  newValue,
  cumulateDuplicatedKeys,
  disableFixed
}) {
  if (typeof newValue !== 'number') {
    return newValue;
  }

  if (cumulateDuplicatedKeys && typeof oldValue === 'number') {
    return fixify(oldValue + newValue, disableFixed);
  }

  return fixify(newValue, disableFixed);
}

/**
 * if `cumulateDuplicatedKeys` is true, repeated keys values will be cumulated
 *  */
export function converDataToChartData(
  data,
  disableFixed,
  cumulateDuplicatedKeys
) {
  const transformed = data[0].Serial.map((_, i) => {
    let dataPoint = {};

    data.forEach(serial => {
      dataPoint[serial.Name] = calculateSerialValue({
        oldValue: dataPoint[serial.Name],
        newValue: serial.Serial[i],
        cumulateDuplicatedKeys,
        disableFixed
      });
    });

    return dataPoint;
  });

  return transformed;
}

export function converDataToChartAccumulatedData(data) {
  const transformed = data[0].Serial.map((_, i) => {
    let dataPoint = {};

    data.forEach(serial =>
      typeof serial.Serial[i] == 'number'
        ? (dataPoint[serial.Name] =
            (dataPoint[serial.Name] ?? 0) + serial.Serial[i])
        : (dataPoint[serial.Name] = serial.Serial[i])
    );

    return dataPoint;
  });
  return transformed;
}

export async function GetCheckoutSummary(p) {
  const { storeID, sdate, edate, stime, etime, filterType } = p;
  if (!storeID || !sdate) {
    return Promise.reject('You should select both store and start date');
  }
  const res = await AxiosInstance.get(
    `/Store/${storeID}/CheckoutSummary?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filterType}&tzoffset=${timeZoneOffset}`
  );
  if (res === 0) {
    return Promise.reject('Üzgünüz hiç veri yok.');
  }
  let data = converDataToChartData(res);
  return data;
}
export async function GetBrandCheckoutSummary(p) {
  const { globalBrandID, sdate, edate } = p;
  if (!globalBrandID || !sdate) {
    return Promise.reject('You should select both Brand and start date');
  }
  //filter default 1
  const res = await AxiosInstance.get(
    `/Brand/${globalBrandID}/CheckoutSummary?sdate=${sdate}&edate=${edate}&filter=1&tzoffset=${timeZoneOffset}`
  );
  if (res === 0) {
    return Promise.reject('Üzgünüz hiç veri yok.');
  }
  return converDataToChartData(res);
}

export async function GetEntranceCount(p) {
  const { storeID, sdate, edate, stime, etime, filterType } = p;
  if (!storeID || !sdate) {
    return Promise.reject('You should select both store and start date');
  }
  const res = await AxiosInstance.get(
    `/Store/${storeID}/EntranceCount?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filterType}&tzoffset=${timeZoneOffset}`
  );
  if (res === 0) {
    return Promise.reject('Üzgünüz hiç veri yok.');
  }
  return converDataToChartData(res);
}

export async function GetLineEntranceCount(p) {
  const { storeID, sdate, edate, stime, etime, filterType } = p;
  if (!storeID || !sdate) {
    return Promise.reject('You should select both store and start date');
  }
  const res = await AxiosInstance.get(
    `/Store/${storeID}/LineEntranceCount?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filterType}&tzoffset=${timeZoneOffset}`
  );
  if (res === 0) {
    return Promise.reject('Üzgünüz hiç veri yok.');
  }
  return converDataToChartAccumulatedData(res);
}

export async function GetLiveOccupancyCount(p) {
  const { storeID, sdate, edate, stime, etime } = p;

  if (!storeID || !sdate) {
    return Promise.reject('You should select both store and start date');
  }

  const res = await AxiosInstance.get(
    `/Store/${storeID}/LiveOccupancyCount?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`
  );

  if (res === 0) {
    return Promise.reject('Üzgünüz hiç veri yok.');
  }

  return converDataToChartAccumulatedData(res);
}

export async function GetCompanyEntranceCount(p) {
  const { brandID, sdate, edate, stime, etime, filterType } = p;
  if (!sdate) {
    return Promise.reject('You should select start date');
  }
  const res = await AxiosInstance.get(
    `/Brand/${brandID}/EntranceCount?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filterType}&tzoffset=${timeZoneOffset}`
  );
  if (res === 0) {
    return Promise.reject('Üzgünüz hiç veri yok :(');
  }
  return converDataToChartData(res);
}

export async function GetAllBrands() {
  const res = await AxiosInstance.get(`/Admin/Brands`);
  return Promise.resolve(res);
}

export async function GetUserStores(brandID) {
  if (!brandID) return Promise.reject('You should select brand!');
  const res = await AxiosInstance.get(`/Brand/${brandID}/Stores`);
  if (!res) {
    return Promise.reject('İşlem gerçekleştirilirken hata oluştu. :(');
  }
  return Promise.resolve(res);
}

export async function GetNotificationRules(p) {
  const { storeID } = p;

  if (!storeID) {
    return Promise.reject('You should select store');
  }

  const res = await AxiosInstance.get(`/Notification/${storeID}/Rules?type=0`);
  if (res === 0) {
    return Promise.reject('Üzgünüz hiç veri yok :(');
  }

  return Promise.resolve(res);
}

export async function SaveNotificationRule(p) {
  const res = await AxiosInstance.post(`/Notification/AddRule`, p);
  return Promise.resolve(res);
}

export async function DeleteNotificationRule(RuleID) {
  const res = await AxiosInstance.post(`/Notification/Rule/${RuleID}/Delete`);
  return Promise.resolve(res);
}

export async function GetNotificationChart(p) {
  const {
    rectID,
    sdate,
    edate,
    stime,
    etime,
    filterType,
    SelectedSketchLayer
  } = p;
  let res;

  if (!rectID) {
    return Promise.reject('Rect ID is required');
  }

  if (SelectedSketchLayer > 1) {
    res = await AxiosInstance.get(
      `/SketchRectLayered/${rectID}/Notifications?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filterType}&tzoffset=${timeZoneOffset}`
    );
  } else {
    res = await AxiosInstance.get(
      `/SketchRect/${rectID}/Notifications?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filterType}&tzoffset=${timeZoneOffset}`
    );
  }
  const d = res;
  return NotificationDataToChart(d);
}
export async function GetDepartmentNotificationChart(p) {
  const { DepartmentID, sdate, edate, stime, etime, filterType } = p;

  if (!DepartmentID) {
    return Promise.reject('Departmend ID is required');
  }

  const res = await AxiosInstance.get(
    `/Rect/${DepartmentID}/Notifications?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filterType}&tzoffset=${timeZoneOffset}`
  );
  const d = res;
  return NotificationDataToChart(d);
}

function NotificationDataToChart(data) {
  if (!Array.isArray(data)) return [];

  const series = [];

  for (const item of data) {
    if (item.Name === 'labels' || item.Name === 'Labels') {
      for (let i = 0; i < item.Serial.length; i++) {
        const Labels = item.Serial.at(i);
        series[i] = { ...(series.at(i) ?? {}), Labels };
      }

      continue;
    }

    const totalSerial = item.Serial.find(s => s.Name === 'total')?.Serial;
    const respondedSerial = item.Serial.find(s => s.Name === 'nores')?.Serial;

    for (let i = 0; i < totalSerial.length; i++) {
      series[i] = {
        ...(series.at(i) ?? {}),
        [`${item.Name}-total`]: totalSerial.at(i),
        [`${item.Name}-nonResponded`]:
          totalSerial.at(i) - respondedSerial.at(i),
        [`${item.Name}-responded`]: respondedSerial.at(i),
        [`${item.Name}-responseRate`]:
          (totalSerial.at(i) === 0
            ? 0
            : Math.round((respondedSerial.at(i) / totalSerial.at(i)) * 100)) +
          '%'
      };
    }
  }

  return series;
}

export async function GetNotificationCamChart(p) {
  const { CamID, sdate, edate, stime, etime, filterType } = p;

  if (!CamID) {
    return Promise.reject('Cam ID is required');
  }

  const res = await AxiosInstance.get(
    `/Camera/${CamID}/Notifications?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filterType}&tzoffset=${timeZoneOffset}`
  );
  const d = res;
  return NotificationDataToChart(d);
}

export async function GetNotificationSummary(payload) {
  const res = await AxiosInstance.post('/Notification/All', payload);

  return Promise.resolve(res);
}

export async function SetUserNotificationSeen(payload) {
  const res = await AxiosInstance.post(
    `/Notification/ComplexRule/ComplexNotUserNotificationSeen`,
    payload
  );
  return Promise.resolve(res);
}

export async function SetUserNotificationResponse(payload) {
  const res = await AxiosInstance.post(
    `/Notification/ComplexRule/ComplexNotUserResponse`,
    payload
  );
  return Promise.resolve(res);
}

export async function SeenNotificationSummary(SeenNotifications) {
  const res = await AxiosInstance.post(`/Notification/Seen`, SeenNotifications);
  if (!res) {
    return Promise.reject('Authorization Failed.');
  }
  return Promise.resolve(res);
}
export async function GetNotificationCount() {
  const res = await AxiosInstance.get(`/Notification/NewCount`);
  if (!res) {
    return Promise.reject('Authorization Failed.');
  }
  return Promise.resolve(res);
}

export async function GetAreaCount(p) {
  const { storeID, sdate, edate, stime, etime } = p;
  if (!storeID || !sdate) {
    return Promise.reject('You should select both store and start date');
  }

  const res = await AxiosInstance.get(
    `/Store/${storeID}/AreaCount?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`
  );

  return Promise.resolve(res);
}

export async function GetCompanyAreaCount(p) {
  const { brandID, sdate, edate, stime, etime } = p;
  if (!sdate) {
    return Promise.reject('You should select start date');
  }

  const res = await AxiosInstance.get(
    `/Brand/${brandID}/AreaCount?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`
  );
  return Promise.resolve(res);
}

export async function GetAreaTime(p) {
  const { storeID, sdate, edate, stime, etime } = p;
  if (!storeID || !sdate) {
    return Promise.reject('You should select both store and start date');
  }

  const res = await AxiosInstance.get(
    `/Store/${storeID}/AreaTime?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`
  );
  return Promise.resolve(res);
}

export async function GetCompanyAreaTime(p) {
  const { brandID, sdate, edate, stime, etime } = p;
  if (!sdate) {
    return Promise.reject('You should select start date');
  }

  const res = await AxiosInstance.get(
    `/Brand/${brandID}/AreaTime?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`
  );
  return Promise.resolve(res);
}

export async function GetCorrelations(p) {
  const { DepartmentID, sdate, edate, stime, etime, isAgeGender } = p;
  if (!DepartmentID || !sdate) {
    return Promise.reject('You should select both department and start date');
  }
  let res;
  if (isAgeGender) {
    res = await AxiosInstance.get(
      `/Rect/${DepartmentID}/DemographyCorrelations?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`
    );
    res = res.reduce((acc, _DemographyCorr) => {
      let DemographyCorr = {
        ..._DemographyCorr,
        value: _DemographyCorr.FemaleCount + _DemographyCorr.MaleCount
      };
      var sameIdValues = acc.findIndex(
        i =>
          i.FromId === DemographyCorr.FromId &&
          i.TargetId === DemographyCorr.TargetId
      );
      if (sameIdValues > -1) {
        acc[sameIdValues] = {
          ...acc[sameIdValues],
          value: acc[sameIdValues].value + DemographyCorr.value,
          FemaleCount:
            acc[sameIdValues].FemaleCount + DemographyCorr.FemaleCount,
          MaleCount: acc[sameIdValues].MaleCount + DemographyCorr.MaleCount,
          YoungCount: acc[sameIdValues].YoungCount + DemographyCorr.YoungCount,
          AdultCount: acc[sameIdValues].AdultCount + DemographyCorr.AdultCount,
          OldCount: acc[sameIdValues].OldCount + DemographyCorr.OldCount
        };
      } else {
        acc.push(DemographyCorr);
      }
      return acc;
    }, []);
  } else {
    res = await AxiosInstance.get(
      `/Rect/${DepartmentID}/Correlations?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`
    );
  }
  return Promise.resolve(res);
}

export async function GetRectFunnel(p) {
  const { DepartmentID, sdate, edate, funnelthresholds, stime, etime } = p;

  if (!DepartmentID || !sdate) {
    return Promise.reject('You should select both department and start date');
  }

  const res = await AxiosInstance.get(
    `/Rect/${DepartmentID}/Funnel?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&funnelthresholds=${funnelthresholds}&tzoffset=${timeZoneOffset}`
  );
  return Promise.resolve(res);
}

export async function getTopCategoryFunnel(p) {
  const {
    rectID,
    sdate,
    edate,
    funnelthresholds,
    stime,
    etime,
    SelectedSketchLayer
  } = p;

  if (!rectID || !sdate) {
    return Promise.reject('You should select both department and start date');
  }
  let res;
  if (SelectedSketchLayer > 1) {
    res = await AxiosInstance.get(
      `/SketchRectLayered/${rectID}/Funnel?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&funnelthresholds=${funnelthresholds}&tzoffset=${timeZoneOffset}`
    );
  } else {
    res = await AxiosInstance.get(
      `/SketchRect/${rectID}/Funnel?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&funnelthresholds=${funnelthresholds}&tzoffset=${timeZoneOffset}`
    );
  }

  return Promise.resolve(res);
}

export async function GetConversionRate(p) {
  const { storeID, sdate, edate, stime, etime } = p;

  if (!storeID || !sdate) {
    return Promise.reject('You should select both store and start date');
  }

  const res = await AxiosInstance.get(
    `/Store/${storeID}/ConversionRate?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`
  );
  return Promise.resolve(res);
}

export async function GetCameras(storeID) {
  if (!storeID) {
    return Promise.reject('You should select store');
  }

  const res = await AxiosInstance.get(`/Store/${storeID}/Cameras`);
  return Promise.resolve(res);
}

export async function GetCamerasWithRects(storeID) {
  if (!storeID) {
    return Promise.reject('You should select store');
  }

  const res = await AxiosInstance.get(
    `/Store/${storeID}/CamerasWithRectangles`
  );

  let arr = [];
  let obj = {};

  res.forEach(d => {
    const cameraName = d.Name;
    d.Rectangles.forEach(rect => {
      rect.Name = `${cameraName} / ${rect.Name}`;
      obj = Object.assign({}, obj, rect);
      arr.push(obj);
    });
  });
  return Promise.resolve(arr);
}

function ImgToBase64(img) {
  let uInt8Array = new Uint8Array(img.photo);
  let i = uInt8Array.length;
  let biStr = new Array(i);
  while (i--) {
    biStr[i] = String.fromCharCode(uInt8Array[i]);
  }
  let data = biStr.join('');
  return window.btoa(data);
}

export async function GetCameraPhoto(cameraID) {
  if (!cameraID) {
    return Promise.reject('You should select camera');
  }

  const res = await AxiosInstance.get(`/Camera/${cameraID}/Photo`, {
    responseType: 'arraybuffer',
    headers: {
      [IsPhoto]: '1'
    }
  });
  if (!res) {
    return Promise.reject('Camera photo is not found.');
  }
  const base64 = ImgToBase64(res);

  return Promise.resolve(base64);
}
export async function GetSettingsCameraPhoto(cameraID) {
  if (!cameraID) {
    return Promise.reject('You should select camera');
  }

  const res = await getRequest({
    url: `/Camera/${cameraID}/Photo`,
    options: {
      responseType: 'arraybuffer',
      headers: {
        [IsPhoto]: '1'
      }
    },
    requestName: 'GetSettingsCameraPhoto'
  });
  if (!res) {
    return Promise.reject('Camera photo is not found.');
  }
  const base64 = ImgToBase64(res);

  return Promise.resolve(base64);
}

export async function GetSettingsCameraRects(p) {
  const { cameraID } = p;

  if (!cameraID) {
    return Promise.reject('You should select camera');
  }

  const res = await getRequest({
    url: `/Camera/${cameraID}/Rectangles`,
    requestName: 'GetSettingsCameraRects'
  });
  return Promise.resolve(res);
}

export async function GetCameraRects(p) {
  const { cameraID } = p;

  if (!cameraID) {
    return Promise.reject('You should select camera');
  }

  const res = await AxiosInstance.get(`/Camera/${cameraID}/Rectangles`);
  return Promise.resolve(res);
}

export async function GetSketchRects(p) {
  const { sketchID, SelectedSketchLayer } = p;

  if (!sketchID) {
    return Promise.reject('You should select sketch');
  }

  const res = await getRequest({
    url: `/Sketch/${sketchID}/Rectangles?layer=${SelectedSketchLayer || 0}`,
    requestName: 'GetSketchRects'
  });
  return Promise.resolve(res);
}

export async function removeLastLayer(sketchId) {
  const res = await AxiosInstance.delete(`/Sketch/${sketchId}/Layer`);
  return Promise.resolve(res);
}

export async function SaveNewSketch(p, file) {
  const { storeID } = p;

  const img = new FormData();
  img.append('img', file);
  const res = await AxiosInstance.post(`/Store/${storeID}/AddSketch`, img);
  return Promise.resolve(res);
}

export async function UploadSketchImage(p, file) {
  const { sketchID } = p;

  const img = new FormData();
  img.append('img', file);

  const res = await AxiosInstance.post(`/Sketch/${sketchID}/Upload`, img);
  return Promise.resolve(res);
}

export async function SaveRects(cameraID, data) {
  const dataObj = {
    cameraid: cameraID,
    ...data
  };

  const res = await AxiosInstance.post(
    `/Camera/${cameraID}/SaveRectangles`,
    dataObj
  );
  return Promise.resolve(res);
}

export async function SaveSketchRects(p, Rectangles) {
  const { SketchId, layer } = p;
  const dataObj = {
    sketchid: SketchId,
    layer: layer,
    Rectangles: Rectangles
  };

  const res = await AxiosInstance.post(
    `/Sketch/${SketchId}/SaveRectangles`,
    dataObj
  );
  return Promise.resolve(res);
}
export async function _GetAreaImage(sketchID, requestName = 'GetAreaImage') {
  if (!sketchID) {
    return Promise.reject('You should select sketch');
  }

  const res = await getRequest({
    url: `/Sketch/${sketchID}/Photo`,
    requestName,
    options: {
      responseType: 'arraybuffer',
      headers: {
        [IsPhoto]: '1'
      }
    }
  });
  const base64 = ImgToBase64(res);
  return Promise.resolve(base64);
}

export async function GetAreaImage(sketchID) {
  if (!sketchID) {
    return Promise.reject('You should select sketch');
  }

  const res = await AxiosInstance.get(`/Sketch/${sketchID}/Photo`, {
    responseType: 'arraybuffer',
    headers: {
      [IsPhoto]: '1'
    }
  });
  const base64 = ImgToBase64(res);
  return Promise.resolve(base64);
}

export async function GetAreaSketchs(storeID) {
  if (!storeID) {
    return Promise.reject('You should select store');
  }

  const res = await AxiosInstance.get(`/Store/${storeID}/Sketches`);
  return Promise.resolve(res);
}

export async function GetAreaSketchMeterSquare(storeID) {
  if (!storeID) {
    return Promise.reject('You should select store');
  }
  const res = await AxiosInstance.get(`Store/${storeID}/sketches `);
  return Promise.resolve(res);
}
export async function SetAreaSketchMeterSquare(sketchID, m2) {
  if (!sketchID) {
    return Promise.reject('You should select Sketch');
  }
  const res = await AxiosInstance.post(
    `Sketch/${sketchID}/SetMetersquare/${m2}`
  );
  return Promise.resolve(res);
}
export async function SetSketchName(sketchID, Name) {
  if (!sketchID) {
    return Promise.reject('You should select Sketch');
  }
  const res = await AxiosInstance.put(`Sketch/${sketchID}/SetName`, {
    Name
  });
  return Promise.resolve(res);
}

export async function GetAreaRectangles(p) {
  const { sketchID, sdate, edate, stime, etime, SelectedSketchLayer } = p;
  if (sketchID === -1) return Promise.resolve(null);
  if (!sketchID || !sdate) {
    return Promise.reject('You should select both sketch and start date');
  }

  const res = await AxiosInstance.get(
    `/Sketch/${sketchID}/Rectangles?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}&layer=${
      SelectedSketchLayer || 1
    }`
  );
  return Promise.resolve(res);
}

export async function _GetAreaRectangles(p) {
  const {
    sketchID,
    sdate,
    edate,
    stime,
    etime,
    SelectedSketchLayer,
    uniqueReq
  } = p;
  if (sketchID === -1) return Promise.resolve(null);
  if (!sketchID || !sdate) {
    return Promise.reject('You should select both sketch and start date');
  }

  const res = await getRequest({
    url: `/Sketch/${sketchID}/Rectangles?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}&layer=${
      SelectedSketchLayer || 1
    }`,
    requestName: uniqueReq ? uniqueReq : 'GetAreaRectangles'
  });
  return Promise.resolve(res);
}

export async function GetRectangleDetail(DepartmentID, SelectedLayer) {
  if (!DepartmentID) {
    return Promise.reject('Alan seçmediniz');
  }
  let res;
  if (SelectedLayer > 1) {
    res = await AxiosInstance.get(`/SketchRectLayered/${DepartmentID}`);
  } else {
    res = await AxiosInstance.get(`/SketchRect/${DepartmentID}`);
  }
  return Promise.resolve(res);
}

export async function GetDepartmentDetail(depID) {
  if (!depID) {
    return Promise.reject('Alan seçmediniz');
  }

  const res = await AxiosInstance.get(`/Rect/${depID}`);
  return Promise.resolve(res);
}

export async function GetStoreRectangles(p) {
  const { storeID } = p;

  if (!storeID) {
    return Promise.reject('Mağaza seçmediniz');
  }

  const res = await AxiosInstance.get(`/Store/${storeID}/Rectangles`);
  return Promise.resolve(res);
}

export async function GetStoreMappedCategories(p) {
  const { Id } = p;
  if (!Id) {
    return Promise.reject('Mağaza seçmediniz');
  }

  const res = await AxiosInstance.get(`/Store/${Id}/MappedCategories`);
  return res;
}

export async function GetRectCountTime(p) {
  const {
    rectID,
    sdate,
    edate,
    stime,
    etime,
    filterType,
    SelectedSketchLayer
  } = p;
  if (!rectID || !sdate) {
    return Promise.reject('You should select both department and start date');
  }
  let res;
  if (SelectedSketchLayer > 1) {
    res = await AxiosInstance.get(
      `/SketchRectLayered/${rectID}/CountandSpenttime?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${
        filterType === '0' ? '4' : filterType
      }&tzoffset=${timeZoneOffset}`
    );
  } else {
    res = await AxiosInstance.get(
      `/SketchRect/${rectID}/CountandSpenttime?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${
        filterType === '0' ? '4' : filterType
      }&tzoffset=${timeZoneOffset}`
    );
  }
  return converDataToChartData(res);
}

export async function GetDepartmentCountTime(p) {
  const { DepartmentID, sdate, edate, stime, etime, filterType } = p;
  if (!DepartmentID || !sdate) {
    return Promise.reject('You should select both department and start date');
  }
  const res = await AxiosInstance.get(
    `/Rect/${DepartmentID}/CountandSpenttime?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${
      filterType === '0' ? '4' : filterType
    }&tzoffset=${timeZoneOffset}`
  );
  return converDataToChartData(res);
}
export async function brandProductTree(p) {
  const { BrandId } = p;
  const res = await getRequest({
    url: `Brand/${BrandId}/ProductTree`,
    requestName: 'brandProductTree'
  });
  return Promise.resolve(res);
}

export async function getStoreProductTree(storeid) {
  const res = await getRequest({
    url: `/Store/${storeid}/MappedProductGroups`,
    requestName: 'getStoreProductTree'
  });
  return Promise.resolve(res);
}

export async function setStoreProductTree(storeid, productTree) {
  const res = AxiosInstance.post(
    `/Store/${storeid}/MapProductGroups`,
    productTree
  );
  return Promise.resolve(res);
}

export async function GetHeatmapData(params) {
  const { cameraID, sdate, edate, stime, etime, CamID } = params;
  if ((!cameraID || !edate) && (!CamID || !edate)) {
    return Promise.reject('You should select both camera and end date');
  }
  const res = await AxiosInstance.get(
    `/Camera/${
      CamID || cameraID
    }/CoordinatesHourly?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`
  );
  let max = 1;
  let min = 0;
  let data = [];
  const datediff = Math.ceil(
    (differenceInCalendarDays(stringToDate(edate), stringToDate(sdate)) + 1) / 7
  );
  if (res.length) {
    data = res;
    max = Math.max(...data.map(c => c['V'])) * datediff;
  }

  // if (max >= 30)
  //   max = max * 2;
  // else if (max >= 60)
  //   max = max * 3;
  const heatMapData = {
    data,
    max
  };
  return Promise.resolve(heatMapData);
}

export async function GetRectangleCounts(params) {
  const { cameraID, sdate, edate, stime, etime, CamID } = params;
  if (!CamID && !cameraID) {
    return Promise.reject('Can not found Camera!');
  }
  const res = await AxiosInstance.get(
    `Camera/${
      CamID || cameraID
    }/RectangleCounts?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`
  );
  return Promise.resolve(res);
}

export async function GetCategories(BrandId) {
  if (!BrandId) {
    return Promise.reject('You should select Brand');
  }
  const res = await AxiosInstance.get(`/Brand/${BrandId}/Categories`);
  return Promise.resolve(res);
}

export async function GetAllDepartments(BrandId) {
  if (!BrandId) {
    return Promise.reject('You should select Brand');
  }
  const res = await AxiosInstance.get(`/Brand/${BrandId}/Departments`);
  return Promise.resolve(res);
}

export function SaveDepartment(dep, BrandId) {
  return AxiosInstance.post(`/Brand/${BrandId}/SaveDepartments`, dep);
}

export function SetDepartment(newdep, BrandId) {
  return AxiosInstance.post(`/Brand/${BrandId}/SetDepartment`, newdep);
}
export async function removeCategories(CategoryIds, brandID) {
  const res = await AxiosInstance.post(
    `/Brand/${brandID}/DeleteDepartments`,
    CategoryIds
  );
  return res;
}

export function SetSubDepartment(SubDep, BrandId) {
  return AxiosInstance.post(`/Brand/${BrandId}/SetSubDepartment`, SubDep);
}
export async function DeleteSubDepartments(CategoryIds, brandID) {
  const res = await AxiosInstance.post(
    `/Brand/${brandID}/DeleteSubDepartments`,
    CategoryIds
  );
  return res;
}

export function SetWorkingHours(storeid, OpenClosehours) {
  return AxiosInstance.post(`/Store/${storeid}/WorkingHours`, OpenClosehours);
}

export function ChangePassword(changePaswordData) {
  return AxiosInstance.post(`/User/ChangePassword`, changePaswordData);
}

export function UpdateStoreLocation(storeid, location) {
  return AxiosInstance.post(`/Store/${storeid}/Location`, location);
}

export async function GetRectSalesChartData(p) {
  const {
    rectID,
    sdate,
    edate,
    stime,
    etime,
    filterType,
    SelectedSketchLayer
  } = p;
  if (!rectID || !sdate) {
    return Promise.reject('You should select both department and start date');
  }
  let res;
  if (SelectedSketchLayer > 1) {
    res = await AxiosInstance.get(
      `/SketchRectLayered/${rectID}/SalesChart?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${
        filterType === '0' ? '4' : filterType
      }&tzoffset=${timeZoneOffset}`
    );
  } else {
    res = await AxiosInstance.get(
      `/SketchRect/${rectID}/SalesChart?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${
        filterType === '0' ? '4' : filterType
      }&tzoffset=${timeZoneOffset}`
    );
  }
  return converDataToChartData(res);
}

export async function GetDepartmentsSalesChartData(p) {
  const { DepartmentID, sdate, edate, stime, etime, filterType } = p;
  if (!DepartmentID || !sdate) {
    return Promise.reject('You should select both department and start date');
  }
  const res = await AxiosInstance.get(
    `/Rect/${DepartmentID}/SalesChart?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${
      filterType === '0' ? '4' : filterType
    }&tzoffset=${timeZoneOffset}`
  );
  return converDataToChartData(res);
}

export async function GetCompanySalesChartData(p) {
  const { brandID, sdate, edate, stime, etime, filterType } = p;
  if (!sdate) {
    return Promise.reject('You should select start date');
  }
  const res = await AxiosInstance.get(
    `/Brand/${brandID}/SalesChart?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${
      filterType === '0' ? '4' : filterType
    }&tzoffset=${timeZoneOffset}`
  );
  return converDataToChartData(res);
}

export async function GetStoreSalesChartData(p) {
  const { storeID, sdate, edate, stime, etime, filterType } = p;
  if (!sdate) {
    return Promise.reject('You should select start date');
  }
  const res = await AxiosInstance.get(
    `/Store/${storeID}/SalesChart?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${
      filterType === '0' ? '4' : filterType
    }&tzoffset=${timeZoneOffset}`
  );
  return converDataToChartData(res);
}

export async function GetDepartmentSales(p) {
  const { DepartmentID, sdate, edate, stime, etime } = p;
  if (!DepartmentID || !sdate) {
    return Promise.reject('You should select both department and start date');
  }
  const res = await AxiosInstance.get(
    `/Rect/${DepartmentID}/Sales?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`
  );
  return res;
}

export async function GetRectSales(p) {
  const { rectID, sdate, edate, stime, etime, SelectedSketchLayer } = p;
  if (!rectID || !sdate) {
    return Promise.reject('You should select both department and start date');
  }
  let res;
  if (SelectedSketchLayer > 1) {
    res = await AxiosInstance.get(
      `/SketchRectLayered/${rectID}/Sales?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`
    );
  } else {
    res = await AxiosInstance.get(
      `/SketchRect/${rectID}/Sales?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`
    );
  }
  return res;
}

export async function ArchiveRectangle(rectid, date) {
  return AxiosInstance.post(`/Rect/${rectid}/Archive?date=${date}`);
}

export function SaveRectCategories(storeid, rectCategories) {
  return AxiosInstance.post(`/Store/${storeid}/MapCategories`, rectCategories);
}

export async function GetBrandComparisonChartData(p) {
  // if (!sdate) {
  //   return Promise.reject('You should select start date');
  // }
  const res = await AxiosInstance.post(`/Brand/${p.BrandId}/ComparisonAll`, p);
  return converDataToChartData(
    res.map(x => ({
      ...x,
      Serial: x.Serial.map(d =>
        Number(d) === d && d % 1 !== 0 ? parseFloat(d.toFixed(2)) : d
      )
    }))
  );
}

export async function SetCameraSketchPosition(
  cameraid,
  sketchid,
  leftpx,
  toppx,
  angle = 0
) {
  if (!cameraid) {
    return Promise.reject('Can not find Camera!');
  }
  const res = await AxiosInstance.post(
    `/Camera/${cameraid}/SketchPosition?sketchid=${sketchid}&leftpx=${Math.floor(
      leftpx
    )}&toppx=${Math.floor(toppx)}&angle=${angle}`
  );
  return res;
}

export async function Getagepie(p) {
  const { storeID, sdate, edate, stime, etime } = p;
  if (!storeID || !sdate) {
    return Promise.reject('You should select both Store and start date');
  }
  const res = await AxiosInstance.get(
    `/Store/${storeID}/agepie?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`
  );
  return converDataToChartData(res);
}

export async function Getgenderline(p) {
  const { storeID, sdate, edate, stime, etime, filterType } = p;
  if (!storeID || !sdate) {
    return Promise.reject('You should select both Store and start date');
  }
  const res = await AxiosInstance.get(
    `/Store/${storeID}/genderline?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filterType}&tzoffset=${timeZoneOffset}`
  );
  return converDataToChartData(res);
}

export async function GetChartMultipleTable(p) {
  const {
    storeID,
    sdate,
    edate,
    stime,
    etime,
    SelectedSketchLayer,
    uniqueReq,
    waitingTimesThresholds,
    funneltdwells
  } = p;
  if (!storeID || !sdate) {
    return Promise.reject('You should select both Store and start date');
  }

  let url = `/Store/${storeID}/AreaTable?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}&layer=${SelectedSketchLayer}`;

  if (
    Array.isArray(waitingTimesThresholds) &&
    waitingTimesThresholds.length > 0
  ) {
    url += `&funnelthresholds=${waitingTimesThresholds}`;
  }

  if (Array.isArray(funneltdwells) && funneltdwells.length > 0) {
    url += `&funneltdwells=${funneltdwells}`;
  }

  if (uniqueReq) {
    return await getRequest({
      url,
      requestName: uniqueReq
    });
  }

  return await AxiosInstance.get(
    `/Store/${storeID}/AreaTable?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}&layer=${SelectedSketchLayer}`
  );
}
export async function GetBrandChartMultipleTable(p) {
  const { brandID, sdate, edate, stime, etime } = p;

  if (!brandID || !sdate) {
    return Promise.reject('You should select both Store and start date');
  }
  const res = await AxiosInstance.get(
    `/Brand/${brandID}/AreaTable?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`
  );
  return res;
}

export async function PushSalesData(StoreID, sales) {
  return await AxiosInstance.post(`/Store/${StoreID}/Sales`, sales);
}

export async function SetGlobalLang(lang) {
  return await AxiosInstance.post(`User/SetLang?lang=${lang}`);
}
export async function GetPassByCountChart(p) {
  const { storeID, sdate, edate, stime, etime, filterType } = p;
  if (!storeID || !sdate) {
    return Promise.reject('You should select both Store and start date');
  }
  const res = await AxiosInstance.get(
    `store/${storeID}/PassByCount?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${
      filterType === '0' ? '4' : filterType
    }&tzoffset=${timeZoneOffset}`
  );
  return res;
}

export async function TourInfoSet(config) {
  var data = JSON.stringify(config);
  await AxiosInstance.post(`/User/SetTour`, data, {
    headers: { 'Content-Type': 'text/plain' }
  });
}

export async function StoresDistribution(p) {
  const { brandID, sdate, edate, stime, etime } = p;
  if (!brandID || !sdate) {
    return Promise.reject('You should select both brand and start date');
  }
  const res = await getRequest({
    url: `/Brand/${brandID}/StoresDistribution?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`,
    requestName: 'StoresDistribution'
  });
  // const res = await AxiosInstance.get(`/Brand/${brandID}/StoresDistribution?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`)
  if (res.pending) return res;
  return converDataToChartData(res);
}
export async function CategoryDistribution(p) {
  const { storeID, sdate, edate, stime, etime, filterType } = p;
  if (!storeID || !sdate) {
    return Promise.reject('You should select both Store and start date');
  }
  const res = await getRequest({
    url: `/Store/${storeID}/CategoryDistribution?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filterType}&tzoffset=${timeZoneOffset}`,
    requestName: 'CategoryDistribution'
  });
  // const res = await AxiosInstance.get(`/Store/${storeID}/CategoryDistribution?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filterType}&tzoffset=${timeZoneOffset}`)
  if (res.pending) return res;
  if (!res) {
    return Promise.reject('Not data available');
  }
  return converDataToChartData(res, true);
}

export async function SubCategoryDistribution(p) {
  const { storeID, sdate, edate, stime, etime, filterType, departmentID } = p;
  if (!storeID || !departmentID) {
    return Promise.reject('You should select both Store and Department');
  }
  const res = await getRequest({
    url: `/Store/${storeID}/SubCategoryDistribution?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&departmentid=${departmentID}&filter=${filterType}&tzoffset=${timeZoneOffset}`,
    requestName: 'SubCategoryDistribution'
  });
  // const res = await AxiosInstance.get(`/Store/${storeID}/SubCategoryDistribution?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&departmentid=${departmentID}&filter=${filterType}&tzoffset=${timeZoneOffset}`)
  if (res.pending) return res;
  if (!res) {
    return Promise.reject('Not data available');
  }
  return converDataToChartData(res, true);
}

export async function CategoryPolyDistribution(p) {
  const { storeID, sdate, edate, stime, etime, filterType, departmentID } = p;
  if (!storeID || !departmentID) {
    return Promise.reject('You should select both Store and Department');
  }
  const res = await getRequest({
    url: `/Store/${storeID}/CategoryPolyDistribution?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&departmentid=${departmentID}&filter=${filterType}&tzoffset=${timeZoneOffset}`,
    requestName: 'CategoryPolyDistribution'
  });
  // const res = await AxiosInstance.get(`/Store/${storeID}/CategoryPolyDistribution?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&departmentid=${departmentID}&filter=${filterType}&tzoffset=${timeZoneOffset}`)
  if (res.pending) return res;
  if (!res) {
    return Promise.reject('Not data available');
  }
  return converDataToChartData(res, true);
}
export async function CategoryPolyDistributionSubCat(p) {
  const {
    storeID,
    sdate,
    edate,
    stime,
    etime,
    filterType,
    subdepartmentID,
    departmentID
  } = p;
  if (!storeID || !departmentID) {
    return Promise.reject('You should select both Store and Department');
  }
  const res = await getRequest({
    url: `/Store/${storeID}/CategoryPolyDistribution?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&departmentid=${departmentID}&subdepartmentid=${subdepartmentID}&filter=${filterType}&tzoffset=${timeZoneOffset}`,
    requestName: 'CategoryPolyDistribution'
  });
  // const res = await AxiosInstance.get(`/Store/${storeID}/CategoryPolyDistribution?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&departmentid=${departmentID}&filter=${filterType}&tzoffset=${timeZoneOffset}`)
  if (res.pending) return res;
  if (!res) {
    return Promise.reject('Not data available');
  }
  return converDataToChartData(res, true);
}

export async function CompanySummary(p) {
  const { globalBrandID, sdate, edate, stime, etime } = p;
  if (!globalBrandID) {
    return Promise.reject('not available Brand');
  }
  const res = await AxiosInstance.get(
    `/Brand/${globalBrandID}/DepartmentsDistribution?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}&filter=3`
  );
  return CompanySummaryDataManipulate(res);
}

function CompanySummaryDataManipulate(data) {
  const dynamicKeys = [
    'Density',
    'Person',
    'TimeSpent',
    'Density/M2',
    'Sale/M2',
    'Density/Sale'
  ];
  const Stores = data[0].Serial;
  let newdata = [];
  var DataKeys = dynamicKeys
    .map(key =>
      Stores.map(s => {
        var store = s.replace(' ', '-');
        return store + key;
      })
    )
    .reduce((a, b) => (a = a.concat(b)));

  const percentagesMaxIndex = Stores.length * 3;
  data.map((d, i) => {
    if (i === 0) {
      let obj = {};
      obj['categories'] = d.Name;
      DataKeys.map((k, j) => (obj[k] = Stores[j % Stores.length]));
      newdata.push(obj);
      return 0;
    }
    let obj = {};

    obj['categories'] = d.Name;
    d.Serial.map((serialval, index) =>
      typeof serialval === 'number'
        ? (obj[DataKeys[index]] =
            index < percentagesMaxIndex
              ? parseFloat((serialval * 100).toFixed(2))
              : parseFloat(serialval.toFixed(2)))
        : (obj[DataKeys[index]] = serialval)
    );
    newdata.push(obj);
  });

  return newdata;
}

export async function CoordinateCounts(p) {
  const { CamID, sdate, edate, stime, etime, filterType } = p;

  const res = await AxiosInstance.get(
    `/Camera/${CamID}/CustomerCounts?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filterType}&tzoffset=${timeZoneOffset}`
  );
  return converDataToChartData(res);
}

export async function GetSuggestions(p) {
  const { storeID, sdate, edate } = p;
  const res = await AxiosInstance.get(
    `/Store/${storeID}/DepartmentComments?sdate=${sdate}&edate=${edate}`
  );
  return res;
}

export async function GetSubCatSuggestions(p) {
  const { storeID, sdate, edate } = p;
  const res = await AxiosInstance.get(
    `/Store/${storeID}/SketchRectComments?sdate=${sdate}&edate=${edate}`
  );
  return res;
}

export async function DepartmentSentFeedback(storeID, feedback) {
  await AxiosInstance.post(`Store/${storeID}/DepCommentFeedback`, feedback);
}
export async function SubDepartmentSentFeedback(storeID, feedback) {
  await AxiosInstance.post(`Store/${storeID}/RectCommentFeedback`, feedback);
}

export async function PostCommentRequest(storeID, CommentRequest) {
  await AxiosInstance.post(`Store/${storeID}/CommentRequest`, CommentRequest);
}
export async function GetCommentRequests(p) {
  const { storeID } = p;
  const res = await AxiosInstance.get(`Store/${storeID}/CommentRequests`);
  return res;
}

export async function PersonnelInOut(p) {
  const { storeID, sdate, edate } = p;
  const res = await getRequest({
    url: `/Store/${storeID}/PersonnelInOut?sdate=${sdate}&edate=${edate}&tzoffset=${timeZoneOffset}`,
    requestName: 'PersonnelInOut'
  });
  if (res.length === 0) return res;
  return converDataToChartData(res);
}

export async function personnelDwell(p) {
  const { storeID, sdate, edate } = p;
  const res = await getRequest({
    url: `/Store/${storeID}/PersonnelDwell?sdate=${sdate}&edate=${edate}`,
    requestName: 'personnelDwell'
  });
  if (res.length === 0) return res;
  return converDataToChartData(res);
}
export async function PersonnelDetailInOut(p) {
  const { storeID, sdate, edate, personnelID } = p;
  const res = await getRequest({
    url: `/Store/${storeID}/PersonnelInOut/${personnelID}?sdate=${sdate}&edate=${edate}&tzoffset=${timeZoneOffset}`,
    requestName: 'PersonnelInOut'
  });
  if (res.length === 0) return res;
  return converDataToChartData(res);
}

export async function personnelDetailDwell(p) {
  const { storeID, sdate, edate, personnelID } = p;
  const res = await getRequest({
    url: `/Store/${storeID}/PersonnelDwell/${personnelID}?sdate=${sdate}&edate=${edate}`,
    requestName: 'personnelDwell'
  });
  if (res.length === 0) return res;
  return converDataToChartData(res);
}

// export async function RectangleTrends(p) {
//   const { storeID, sdate, edate, trendsFilterType } = p
//   const res = await getRequest({
//     url: `/Store/${storeID}/RectangleTrends?sdate=${sdate}&edate=${edate}${trendsFilterType ? "&filter=" + trendsFilterType : ""}`, requestName: 'RectangleTrends'
//   });
//   if (res.length === 0)
//     return res
//   return converDataToChartData(res);
// }

export async function SketchRectangleTrends(p) {
  const { trendsSketch, sdate, edate, trendsFilterType } = p;
  const res = await getRequest({
    url: `/Sketch/${trendsSketch}/Trends?edate=${edate}${
      trendsFilterType ? '&filter=' + trendsFilterType : ''
    }`,
    requestName: 'SketchRectangleTrends'
  });

  return res;
}

export async function RectangleTrends(p) {
  const { storeID, metric, SelectedLayer } = p;
  if (!storeID) {
    return Promise.reject('You should select store.');
  }
  const res = await AxiosInstance.get(
    `/Store/${storeID}/RectangleTrends/${metric}?layer=${SelectedLayer}`
  );
  return res;
}

export async function getWeather(p) {
  const { storeID, sdate, edate } = p;
  if (!storeID) {
    return Promise.reject('Select a Store.');
  }
  const res = await AxiosInstance.get(
    `/Store/${storeID}/Weather?sdate=${sdate}&edate=${edate}`
  );
  return res;
}

export async function getUsers(StoreID) {
  const res = await getRequest({
    url: `/Store/${StoreID}/Users`,
    requestName: 'getUsers'
  });
  return res;
}
export async function PostAddForecast(storeID, group) {
  await AxiosInstance.post(`Store/${storeID}/ForecastGroup`, group);
}
export async function getForecastGroup({ storeID }) {
  if (!storeID) {
    return Promise.reject('Select a Store.');
  }
  const res = await getRequest({
    url: `/Store/${storeID}/ForecastGroups`,
    requestName: 'getForecast'
  });
  return res;
}
export async function RemoveForecastGroup(storeID, groupid) {
  await AxiosInstance.post(`Store/${storeID}/RemoveForecastGroup/${groupid}`);
}
export async function getPeopleCount(storeID) {
  if (!storeID) return Promise.reject(false);
  const res = await AxiosInstance.get(`/Store/${storeID}/PeopleCount`);
  return res;
}
export async function postPeopleCount(storeID, value) {
  await AxiosInstance.post(`Store/${storeID}/AddEntrance/${value}`);
}

export async function getCustomerCount(p) {
  const { storeID, sdate, edate } = p;
  if (!storeID) {
    return Promise.reject('Select a Store.');
  }
  const res = await getRequest({
    url: `/Store/${storeID}/CustomerCount?sdate=${sdate}&edate=${edate}&tzoffset=${timeZoneOffset}`,
    requestName: 'getCustomerCount'
  });
  return converDataToChartData(res);
}
export async function postCustomerCount(storeid, value) {
  await AxiosInstance.post(`/Store/${storeid}/Capacity/${value}`);
}
export async function GetNotResponses(brandId) {
  const res = await getRequest({
    url: `/Notification/${brandId}/Responses`,
    requestName: 'getNotResponses'
  });
  return res;
}
export async function PostNotResponses(NotificationId, response) {
  await AxiosInstance.post(
    `/Notification/${NotificationId}/SetResponse`,
    response
  );
}
export async function InvalidateData(rectID, date) {
  await AxiosInstance.post(`/Rect/${rectID}/InvalidateData`, date, {
    headers: {
      'Content-Type': 'text/plain'
    }
  });
}

export async function rectangleChangeCamera(rectID, toCameraId) {
  const res = await AxiosInstance.post(
    `/Rect/${rectID}/ChangeCamera/${toCameraId}`
  );
  return Promise.resolve(res);
}

export async function SketchRectComments(p) {
  const { SuggestionSelectRectId, SuggestionsPeriod, sdate } = p;
  const res = await getRequest({
    url: `/SketchRect/${SuggestionSelectRectId}/Comments?sdate=${sdate}&period=${SuggestionsPeriod}`,
    requestName: 'getSketchRectComments'
  });
  return res;
}
export async function GetSugCategoryRequest(p) {
  const { storeID, SuggestionSelectDepId, sdate, edate } = p;
  if (!SuggestionSelectDepId || isNaN(SuggestionSelectDepId)) {
    Promise.reject('Departman bulunamadı.');
  }
  if (!storeID) {
    Promise.reject('Select a store.');
  }
  const res = await AxiosInstance.get(
    `/Store/${storeID}/Summary/${SuggestionSelectDepId}?sdate=${sdate}&edate=${edate}`
  );
  return res;
}
export async function GetSugStoreRequest(p) {
  const { storeID, sdate, edate } = p;
  const res = await AxiosInstance.get(
    `/Store/${storeID}/Summary?sdate=${sdate}&edate=${edate}`
  );
  return res;
}

export async function GetLiveCamerasCount(p) {
  const { brandID } = p;
  if (!brandID) {
    return Promise.reject('Select a Brand.');
  }
  const res = await getRequest({
    url: `/brand/${brandID}/CounterStatus`,
    requestName: 'GetLiveCamerasCount'
  });
  return res;
}
export async function BoardStatuses(p) {
  const { brandID } = p;
  if (!brandID) {
    return Promise.reject('Select a Brand.');
  }
  const res = await getRequest({
    url: `/Brand/${brandID}/BoardStatuses`,
    requestName: 'BoardStatuses'
  });
  return res;
}

export async function GetStoreSuggestionComments(p) {
  const { storeID, edate } = p;
  if (!storeID) {
    return Promise.reject('Select a Store.');
  }
  const res = await getRequest({
    url: `/Store/${storeID}/Comments?sdate=${edate}`,
    requestName: 'GetStoreSuggestionComments'
  });
  return res;
}

export async function storeCommentPopupSeen(storeID) {
  if (!storeID) {
    return Promise.reject('Select a Store.');
  }
  const res = await getRequest({
    url: `/Store/${storeID}/CommentPopupSeen`,
    requestName: 'storeCommentPopupSeen'
  });
  return res || false;
}

export async function setCommentPopupSeen(storeID) {
  const res = await AxiosInstance.post(`/Store/${storeID}/CommentPopupSeen`);
  return Promise.resolve(res);
}
export async function PerformanceTableFilter(config) {
  var data = JSON.stringify({ ...config });
  await AxiosInstance.post(`/User/PanelConfig`, data, {
    headers: { 'Content-Type': 'text/plain' }
  });
}

export async function SetPanelConfig(config) {
  var data = JSON.stringify({ ...config });
  await AxiosInstance.post(`/User/PanelConfig`, data, {
    headers: { 'Content-Type': 'text/plain' }
  });
}

export async function companyLineEntranceCount({
  globalBrandID,
  sdate,
  edate,
  stime,
  etime,
  filterType
}) {
  if (!globalBrandID) {
    return Promise.reject();
  }
  const res = await getRequest({
    url: `/Brand/${globalBrandID}/LineEntranceCount?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filterType}&tzoffset=${timeZoneOffset}`
  });
  return converDataToChartData(res);
}
export async function postCameraPhotoNot(storeID, mailRequests) {
  await AxiosInstance.post(`/Store/${storeID}/CameraPhotoNot`, mailRequests);
}
export async function getCameraPhotoNot({ storeID }) {
  if (!storeID) {
    return Promise.reject('Select a store. :(');
  }
  const res = await getRequest({
    url: `/Store/${storeID}/CameraPhotoNot`,
    requestName: 'GetCameraSnapShot'
  });
  return res;
}
export async function removeCamPhotoNot(CamsIds, storeID) {
  const res = await AxiosInstance.post(
    `/Store/${storeID}/DeleteCameraPhotoNot`,
    CamsIds
  );
  return res;
}
export async function getStoreDetails(storeID) {
  if (!storeID) {
    return Promise.reject('Select a Store.');
  }
  const res = await getRequest({
    url: `/Store/${storeID}`,
    requestName: 'getStoreDetails'
  });
  if (!res) {
    return Promise.reject("Store id isn't recognized!");
  }
  return res;
}
export async function getPieChartComp(p) {
  const { brandID, sdate, edate, stime, etime } = p;
  const res = await getRequest({
    url: `/Brand/${brandID}/GenderAgeDistribution?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`,
    requestName: 'GenderAgeDistribution'
  });
  return converDataToChartData(res);
}
export async function GetBoards(storeID) {
  if (!storeID) {
    return Promise.reject('You should select store');
  }

  const res = await AxiosInstance.get(`/Store/${storeID}/Boards`);
  return Promise.resolve(res);
}

export async function SetBoardPosition(boardid, sketchid, leftpx, toppx) {
  if (!boardid) {
    return Promise.reject('Can not find Board!');
  }
  const res = await AxiosInstance.post(
    `/Board/${boardid}/SketchPosition?sketchid=${sketchid}&leftpx=${Math.floor(
      leftpx
    )}&toppx=${Math.floor(toppx)}`
  );
  return res;
}
export async function getPersonnelSummary(p) {
  const { storeID, sdate, edate } = p;
  if (!storeID) {
    return Promise.reject('Select a store. :(');
  }
  const res = await getRequest({
    url: `/Store/${storeID}/PersonnelSummary?sdate=${sdate}&edate=${edate}`,
    requestName: 'GetPersonnelSummary'
  });
  return res;
}
export async function getCheckOutCounts(p) {
  const { storeID, sdate, edate, filterType, stime, etime } = p;

  if (!storeID) {
    return Promise.reject('Store is not selected.');
  }

  const res = await AxiosInstance.get(
    `/Store/${storeID}/CheckoutCounts?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filterType}&tzoffset=${timeZoneOffset}`
  );
  return converDataToChartData(res);
}
export async function getCheckOutDensity(p) {
  const { storeID, sdate, edate, filterType, stime, etime } = p;
  if (!storeID) {
    return Promise.reject('Select a Store.');
  }
  const res = await AxiosInstance.get(
    `/Store/${storeID}/CheckoutDensity?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filterType}&tzoffset=${timeZoneOffset}`
  );
  return res;
}

export async function setBeaconToBoard(boardID, beaconID) {
  const res = await AxiosInstance.post(
    `/Board/${boardID}/Target?targetid=${beaconID}`
  );
  return res;
}

export async function removeBeaconFromBoard(boardID) {
  const res = await AxiosInstance.post(`/Board/${boardID}/Target`);
  return res;
}

export async function getPersonnels(storeID) {
  const res = await AxiosInstance.get(`/Store/${storeID}/Personnels`);
  return res;
}
export async function getBrandLogo(brandID) {
  const res = await AxiosInstance.get(`/Brand/${brandID}/Logo`, {
    responseType: 'arraybuffer',
    headers: {
      [IsPhoto]: '1'
    }
  });
  const base64 = ImgToBase64(res);
  return Promise.resolve(base64);
}
export async function getSaleConversionRate(p) {
  const {
    DepartmentID,
    conversionRateThresholds,
    sdate,
    edate,
    stime,
    etime,
    filterType
  } = p;
  if (!DepartmentID) {
    return Promise.reject('You should select both department');
  }
  let res;
  let requestStr = `/Rect/${DepartmentID}/CountandSpenttimeThreshold?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${
    filterType === '0' ? '4' : filterType
  }&tzoffset=${timeZoneOffset}`;
  if (conversionRateThresholds) {
    requestStr += `&funnelthresholds=${conversionRateThresholds}`;
  }
  res = await AxiosInstance.get(requestStr);
  return converDataToChartData(res);
}
export async function getTopCategorySaleConversionRate(p) {
  const {
    rectID,
    conversionRateThresholds,
    sdate,
    edate,
    stime,
    etime,
    filterType,
    SelectedSketchLayer
  } = p;
  let res;

  if (!rectID) {
    return Promise.reject('You should select both department');
  }
  if (SelectedSketchLayer > 1) {
    res = await AxiosInstance.get(
      `/SketchRectLayered/${rectID}/CountandSpenttimeThreshold?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${
        filterType === '0' ? '4' : filterType
      }&tzoffset=${timeZoneOffset}&funnelthresholds=${conversionRateThresholds}`
    );
  } else {
    res = await AxiosInstance.get(
      `/SketchRect/${rectID}/CountandSpenttimeThreshold?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${
        filterType === '0' ? '4' : filterType
      }&tzoffset=${timeZoneOffset}&funnelthresholds=${conversionRateThresholds}`
    );
  }
  return converDataToChartData(res);
}

export async function getGenderAgeDistribution(p) {
  const { storeID, sdate, edate, stime, etime } = p;
  const res = await AxiosInstance.get(
    `/Store/${storeID}/GenderAgeDistribution?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`
  );
  return converDataToChartData(res, true);
}
export async function getStoresDepartments(p) {
  const { brandID, sdate, edate, stime, etime } = p;
  const res = await getRequest({
    url: `/Brand/${brandID}/StoresDepartmentsDistribution?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`
  });
  return converDataToChartData(res);
}
export async function getDepartmentDistribution(p) {
  const { brandID, sdate, edate, stime, etime, DepartmentID, filterType } = p;
  if (!brandID || !sdate) {
    return Promise.reject('You should select both Store and start date');
  }
  const res = await getRequest({
    url: `/Brand/${brandID}/DepartmentDistribution?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filterType}&departmentid=${DepartmentID}&tzoffset=${timeZoneOffset}`
  });
  if (res.pending) return res;
  if (!res) {
    return Promise.reject('Not data available');
  }
  return converDataToChartData(res, true);
}

export async function CustomerIframe(storeId, store) {
  const res = await AxiosInstance.put(
    `/Store/${storeId}/CustomReportUrl`,
    store
  );
  return res;
}

export async function CreateCampaigns(data) {
  const res = await AxiosInstance.post(`/Campaigns`, data);
  return res;
}

export async function GetCampaigns(p) {
  const { brandID } = p;
  if (!brandID) {
    return Promise.reject('Select a Brand.');
  }
  const res = await getRequest({
    url: `/Campaigns?brandId=${brandID}`,
    requestName: 'GetAllCampaigns'
  });
  return res;
}
export async function UpdateCampaign(id, data) {
  const res = await AxiosInstance.put(`/Campaigns/${id}`, data);
  return res;
}

export async function GetRawSales(p) {
  const { storeID, sdate, edate, stime, etime, filterType } = p;
  if (!storeID) {
    return Promise.reject('You should select store');
  }
  const res = await getRequest({
    url: `/Store/${storeID}/RawSales?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filterType}&tzoffset=${timeZoneOffset}`,
    requestName: 'GetStoreRawSales'
  });
  return converDataToChartData(res);
}

export async function CompareCampaigns(data) {
  const res = await AxiosInstance.post(`/Campaigns/CompareCampaigns`, data);
  return res;
}

export async function GetLineMoveCounts(params) {
  const {
    brandID,
    storeID,
    sdate,
    edate,
    stime,
    etime,
    filterType,
    lineType = 0,
    showAllBrand,
    uniqueReq
  } = params;
  if (!storeID) {
    return Promise.reject('You should select store');
  } else if (showAllBrand && !brandID) {
    return Promise.reject('You should select brand');
  }
  const res = await getRequest({
    url: `${showAllBrand ? 'Brand' : 'Store'}/${
      showAllBrand ? brandID : storeID
    }/LineMoveCounts?${showAllBrand ? `brandid=${brandID}&` : ''}${
      showAllBrand ? `storeid=${storeID}&` : ''
    }sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}&filter=${filterType}&lineType=${lineType}`,
    requestName: 'GetStoreLineCounts'
  });

  if (res.length !== 0) return converDataToChartData(res, undefined, true);
  else return Promise.reject('No data available.');
}
export async function GetLineMoveCountsType(params) {
  const {
    storeID,
    sdate,
    edate,
    stime,
    etime,
    filterType,
    lineType = 1
  } = params;
  if (!storeID) {
    return Promise.reject('You should select store');
  }
  const res = await getRequest({
    url: `Store/${storeID}/LineMoveCounts?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}&filter=${filterType}&lineType=${lineType}`,
    requestName: 'GetStoreLineMoveCounts'
  });

  if (res.length !== 0) {
    const convertedRes = equalNameFindSerialTotal(res);
    return converDataToChartData(convertedRes);
  }

  return Promise.reject('No data available.');
}
export async function GetCameraLineMoveCounts(params) {
  const {
    CamID,
    sdate,
    edate,
    stime,
    etime,
    filterType,
    lineType = 1
  } = params;
  if (!CamID) {
    return Promise.reject('You should select Camera');
  }
  const res = await getRequest({
    url: `Camera/${CamID}/LineMoveCounts?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}&filter=${filterType}&lineType=${lineType}`,
    requestName: 'GetCameraLineMoveCounts'
  });

  if (res.length !== 0) return [[], converDataToChartData(res)];
  return Promise.reject(' ');
}
export async function GetPersonnelTimeDistribution(p) {
  const { storeID, personnelid, boardid, sdate, edate } = p;
  if (!storeID) {
    return Promise.reject('You should select store');
  }
  const res = await getRequest({
    url: `/Store/${storeID}/PersonnelTimeDistribution?personnelid=${personnelid}&boardid=${boardid}&sdate=${sdate}&edate=${edate}`,
    requestName: 'GetPersonnelTimeDistribution'
  });
  return res;
}

export async function GetBrandsgenderline(p) {
  const { sdate, edate, stime, etime, filterType, brandID } = p;
  if (!brandID || !sdate) {
    return Promise.reject('You should select both brand and start date');
  }
  const res = await AxiosInstance.get(
    `/Brand/${brandID}/genderline?brandid=${brandID}&sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filterType}&tzoffset=${timeZoneOffset}`
  );
  return converDataToChartData(res);
}

export async function GetBrandRawSales(p) {
  const { sdate, edate, stime, etime, brandID, filterType } = p;
  if (!brandID) {
    return Promise.reject('You should select brand');
  }
  const res = await getRequest({
    url: `/Brand/${brandID}/RawSales?brandid=${brandID}&sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filterType}&tzoffset=${timeZoneOffset}`,
    requestName: 'GetBrandsRawSales'
  });
  return converDataToChartData(res);
}
export async function GetBrandagepie(p) {
  const { sdate, edate, stime, etime, brandID } = p;
  if (!brandID) {
    return Promise.reject('You should select brand');
  }
  const res = await AxiosInstance.get(
    `/Brand/${brandID}/agepie?brandid=${brandID}&sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`
  );
  return converDataToChartData(res);
}

export async function CompareCampaignStores(data) {
  const res = await AxiosInstance.post(
    `/Campaigns/CompareCampaignStores`,
    data
  );
  return res;
}

export async function CompareStoreCategoriesByMetric(data) {
  const res = await AxiosInstance.post(
    `/Campaigns/CompareStoreCategoriesByMetric`,
    data
  );
  return converDataToChartData(res);
}
export async function GetReceivers(storeID) {
  if (!storeID) {
    return Promise.reject('You should select store');
  }

  const res = await AxiosInstance.get(`/Store/${storeID}/Receivers`);
  return Promise.resolve(res);
}

export async function SetReceiverPosition(
  receiverid,
  sketchid,
  leftpx,
  toppx,
  receiverType
) {
  if (!receiverid) {
    return Promise.reject('Can not find Board!');
  }

  // For updating/adding receiver
  if (receiverType !== null && receiverType !== undefined) {
    const res = await AxiosInstance.post(
      `/Receiver/${receiverid}/SketchPosition?sketchid=${sketchid}&leftpx=${leftpx}&toppx=${toppx}&receivertype=${receiverType}`
    );
    return res;
  }

  // For deleting the receiver -we do not send receiver type param-
  const res = await AxiosInstance.post(
    `/Receiver/${receiverid}/SketchPosition?sketchid=${sketchid}&leftpx=${leftpx}&toppx=${toppx}`
  );
  return res;
}

export async function SetPersonnelsToReceiver(params) {
  const { receiverId, personnels } = params;
  if (!receiverId || !personnels) {
    return Promise.reject('Receiver and/or Personnels is empty');
  }
  const res = await AxiosInstance.post(
    `/Receiver/${receiverId}/Target`,
    personnels
  );
  return res;
}

export async function GetBeaconSummary(storeId, sdate, edate) {
  if (!storeId) {
    return Promise.reject('Store is not selected.');
  }
  const res = await AxiosInstance.get(
    `/Store/${storeId}/BeaconSummary?&sdate=${sdate}&edate=${edate}&tzoffset=${timeZoneOffset}`
  );
  return res;
}

export async function GetReceiverBeaconDistribution(
  receiverId,
  sdate,
  edate,
  filterType
) {
  if (!receiverId) {
    return Promise.reject('Receiver is not selected.');
  }
  const res = await AxiosInstance.get(
    `/Receiver/${receiverId}/BeaconDistribution?sdate=${sdate}&edate=${edate}&tzoffset=${timeZoneOffset}&filter=${
      filterType === '2' ? '0' : filterType
    }`
  );
  return converDataToChartData(res);
}

export async function GetReceiverBeaconEntranceDwell(params) {
  const { receiverId, sdate, edate, stime, etime } = params;
  if (!receiverId) {
    return Promise.reject('Receiver is not selected.');
  }
  const res = await AxiosInstance.get(
    `/Receiver/${receiverId}/BeaconEntranceDwell?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`
  );
  return res;
}

export async function GetReceiverSummaries(params) {
  const { sdate, edate, storeID } = params;
  if (!storeID) {
    return Promise.reject('Store is not selected.');
  }
  const res = await AxiosInstance.get(
    `/Beacons/Summaries?sdate=${sdate}&edate=${edate}&storeid=${storeID}&tzoffset=${timeZoneOffset}`
  );
  return res;
}

export async function GetPersonnelSummary(params) {
  const { sdate, edate, selectedPersonnel } = params;
  if (!selectedPersonnel?.Id) {
    return Promise.reject('Personnel is not selected.');
  }
  const res = await AxiosInstance.get(
    `/Beacons/${selectedPersonnel.Id}/Summary?sdate=${sdate}&edate=${edate}&tzoffset=${timeZoneOffset}`
  );
  return res;
}

export async function GetReceiverDwellTimeDistributions(params) {
  const { sdate, edate, selectedPersonnel } = params;
  if (!selectedPersonnel?.Id) {
    return Promise.reject('Personnel is not selected.');
  }
  const res = await AxiosInstance.get(
    `/Beacons/${selectedPersonnel.Id}/ReceiverDwellTimeDistributions?sdate=${sdate}&edate=${edate}&tzoffset=${timeZoneOffset}`
  );
  return res;
}

export async function GetReceiverEntanceCountDistributions(params) {
  const { sdate, edate, selectedPersonnel } = params;
  if (!selectedPersonnel?.Id) {
    return Promise.reject('Personnel is not selected.');
  }
  const res = await AxiosInstance.get(
    `/Beacons/${selectedPersonnel.Id}/ReceiverEntanceCountDistributions?sdate=${sdate}&edate=${edate}&tzoffset=${timeZoneOffset}`
  );
  return res;
}
export async function GetEntranceDistributionAll(p) {
  const { brandID, sdate, edate, stime, etime, metricType } = p;

  if (!sdate || !edate) {
    return Promise.reject('You should select both start data and end date');
  } else if (!brandID) {
    return Promise.reject('You should select brand');
  }
  const res = await AxiosInstance.get(
    `/Brand/${brandID}/EntranceDistribution?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}&metrictype=${metricType}`
  );
  return converDataToChartData(res);
}
export async function GetEntranceDistributionDaily(p) {
  const { brandID, sdate, edate, stime, etime, metricType } = p;

  if (!sdate || !edate) {
    return Promise.reject('You should select both start data and end date');
  } else if (!brandID) {
    return Promise.reject('You should select brand');
  }
  const res = await AxiosInstance.get(
    `/Brand/${brandID}/EntranceDistribution?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}&filter=1&metrictype=${metricType}`
  );
  return res;
}
export async function GetEntranceDistributionHourly(p) {
  const { brandID, sdate, edate, stime, etime, metricType } = p;

  if (!sdate || !edate) {
    return Promise.reject('You should select both start data and end date');
  } else if (!brandID) {
    return Promise.reject('You should select brand');
  }
  const res = await AxiosInstance.get(
    `/Brand/${brandID}/EntranceDistribution?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}&filter=0&metrictype=${metricType}`
  );
  return res;
}
export async function GetExcel(params) {
  const { sdate, edate, selectedPersonnel } = params;
  if (!selectedPersonnel?.Id) {
    return Promise.reject('Personnel is not selected.');
  }
  const res = await AxiosInstance.get(
    `/Beacons/${selectedPersonnel.Id}/DownloadDetailedSummary?sdate=${sdate}&edate=${edate}&tzoffset=${timeZoneOffset}`,
    {
      responseType: 'blob',
      headers: {
        [IsFile]: '1'
      }
    }
  );

  downloadFile(res);
}

const downloadFile = response => {
  const blob = new Blob([response.file], { type: response.contentType });
  let dom = document.createElement('a');
  let url = window.URL.createObjectURL(blob);
  dom.href = url;
  dom.download = decodeURI(response.fileName);
  dom.style.display = 'none';
  document.body.appendChild(dom);
  dom.click();
  dom.remove();
  window.URL.revokeObjectURL(url);
};

export async function GetBeaconSummariesExcel(params) {
  const { sdate, edate, storeID, lang, ignoredColumns } = params;
  if (!storeID) {
    Promise.reject('Store is not selected.');
  }
  const res = await AxiosInstance.get(
    `/Beacons/DownloadBeaconSummaries?sdate=${sdate}&edate=${edate}&storeid=${storeID}&tzoffset=${timeZoneOffset}&filter=0&ignoredColumns=${ignoredColumns}`,
    {
      responseType: 'arraybuffer',
      headers: { 'Accept-Language': lang, [IsFile]: '1' }
    }
  );

  downloadFile(res);
}

export async function GetDetailedBeaconSummariesExcel(params) {
  const { sdate, edate, storeID, lang } = params;
  if (!storeID) {
    Promise.reject('Store is not selected.');
  }
  const res = await AxiosInstance.get(
    `/Beacons/DownloadBeaconSummaries?sdate=${sdate}&edate=${edate}&storeid=${storeID}&tzoffset=${timeZoneOffset}&filter=1`,
    {
      responseType: 'arraybuffer',
      headers: { 'Accept-Language': lang, [IsFile]: '1' }
    }
  );

  downloadFile(res);
}

export async function GetBeaconSummaryExcel(params) {
  const { sdate, edate, selectedPersonnel, lang, ignoredColumns } = params;
  if (!selectedPersonnel?.Id) {
    return Promise.reject('Personnel is not selected.');
  }
  const res = await AxiosInstance.get(
    `/Beacons/${selectedPersonnel.Id}/DownloadBeaconSummary?sdate=${sdate}&edate=${edate}&tzoffset=${timeZoneOffset}&filter=0&ignoredColumns=${ignoredColumns}`,
    {
      responseType: 'arraybuffer',
      headers: { 'Accept-Language': lang, [IsFile]: '1' }
    }
  );

  downloadFile(res);
}

export async function GetDetailedBeaconSummaryExcel(params) {
  const { sdate, edate, selectedPersonnel, lang } = params;
  if (!selectedPersonnel?.Id) {
    return Promise.reject('Personnel is not selected.');
  }
  const res = await AxiosInstance.get(
    `/Beacons/${selectedPersonnel.Id}/DownloadBeaconSummary?sdate=${sdate}&edate=${edate}&tzoffset=${timeZoneOffset}&filter=1`,
    {
      responseType: 'arraybuffer',
      headers: { 'Accept-Language': lang, [IsFile]: '1' }
    }
  );

  downloadFile(res);
}

export async function GetAllReportsOfStore(storeID, sdate, edate) {
  if (!storeID) {
    return Promise.reject('Store is not selected.');
  }
  if (!(sdate && edate)) {
    return Promise.reject('Select startDate and endDate.');
  }
  const res = await AxiosInstance.get(
    `/Report/${storeID}?startDate=${sdate}&endDate=${edate}`
  );
  return res;
}

export async function GetReport(storeID, reportId) {
  if (!storeID) {
    return Promise.reject('Store is not selected.');
  }
  if (!reportId) {
    return Promise.reject('ReportID is invalid.');
  }
  const res = await AxiosInstance.get(`/Report/${storeID}/${reportId}`, {
    responseType: 'blob',
    headers: {
      [IsFile]: '1'
    }
  });

  downloadFile(res);
}

export async function DeleteReport(storeID, reportId) {
  if (!storeID) {
    return Promise.reject('Store is not selected.');
  }
  if (!reportId) {
    return Promise.reject('ReportID is invalid.');
  }

  await AxiosInstance.delete(`/Report/${storeID}/${reportId}`);
}

export async function UploadReport(storeID, form, reportFile) {
  if (!storeID) {
    return Promise.reject('Store is not selected.');
  }

  let formData = new FormData();
  formData.append('model', form);
  formData.append('report', reportFile);

  const res = await AxiosInstance.post(`/Report/${storeID}/Save`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

  return res;
}

export async function getBeacons(storeID) {
  const res = await AxiosInstance.get(`/Store/${storeID}/Beacons`);
  return res;
}

export async function GetAllReceiversExcel(params) {
  const { sdate, edate, storeID, lang, ignoredColumns } = params;
  if (!storeID) {
    return Promise.reject('Store is not selected.');
  }
  const res = await AxiosInstance.get(
    `/Store/${storeID}/DownloadBeaconSummary?sdate=${sdate}&edate=${edate}&tzoffset=${timeZoneOffset}&ignoredColumns=${ignoredColumns}`,
    {
      responseType: 'arraybuffer',
      headers: { 'Accept-Language': lang, [IsFile]: '1' }
    }
  );

  downloadFile(res);
}

export async function GetBeaconDistributionExcel(params) {
  const { sdate, edate, receiverID, lang } = params;
  if (!receiverID) {
    return Promise.reject('Store is not selected.');
  }
  const res = await AxiosInstance.get(
    `/Receiver/${receiverID}/DownloadBeaconDistribution?sdate=${sdate}&edate=${edate}&tzoffset=${timeZoneOffset}`,
    {
      responseType: 'arraybuffer',
      headers: { 'Accept-Language': lang, [IsFile]: '1' }
    }
  );

  downloadFile(res);
}

export async function GetStoreBaseSummaries(params) {
  const { sdate, edate, storeID } = params;

  if (!storeID) {
    return Promise.reject('Store is not selected.');
  }

  const res = await AxiosInstance.get(
    `/Beacons/StoreBaseSummaries?sdate=${sdate}&edate=${edate}&tzoffset=${timeZoneOffset}&storeid=${storeID}`
  );

  return res;
}

export async function GetStoreSummaries(params) {
  const { sdate, edate, storeID } = params;

  if (!storeID) {
    return Promise.reject('Store is not selected.');
  }

  const res = await AxiosInstance.get(
    `/Beacons/StoreSummaries?sdate=${sdate}&edate=${edate}&tzoffset=${timeZoneOffset}&storeid=${storeID}`
  );

  return res;
}

export async function getPeopleCounts(
  storeId,
  brandId,
  sdate,
  stime,
  edate,
  etime,
  filter
) {
  if (!storeId) {
    return Promise.reject('Store is not selected.');
  }
  if (!brandId) {
    return Promise.reject('BrandId is not selected.');
  }
  const res = await AxiosInstance.get(
    `Brand/${brandId}/PeopleCounts?storeid=${storeId}&sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filter}&tzoffset=${timeZoneOffset}`
  );

  return converDataToChartData(res);
}

export async function getBreachCounts(
  storeId,
  brandId,
  sdate,
  stime,
  edate,
  etime,
  filter,
  type = 4
) {
  if (!storeId) {
    return Promise.reject('Store is not selected.');
  }
  if (!brandId) {
    return Promise.reject('BrandId is not selected.');
  }
  const res = await AxiosInstance.get(
    `Brand/${brandId}/BreachCounts?type=${type}&storeid=${storeId}&sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filter}&tzoffset=${timeZoneOffset}`
  );

  return converDataToChartData(res);
}

export async function GetCheckoutWaitingSecond(params) {
  const { sdate, edate, storeID, stime, etime } = params;

  if (!storeID) {
    return Promise.reject('Store is not selected.');
  }

  const res = await AxiosInstance.get(
    `Store/${storeID}/Checkout/CheckoutWaitingSecond?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`
  );

  return res;
}

export async function GetMaxCheckoutVisitorCount(params) {
  const { sdate, edate, storeID, stime, etime } = params;

  if (!storeID) {
    return Promise.reject('Store is not selected.');
  }

  const res = await AxiosInstance.get(
    `Store/${storeID}/Checkout/MaxCheckoutVisitorCount?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`
  );

  return res;
}

export async function GetCheckoutOccupancy(storeId) {
  if (!storeId) {
    return Promise.reject('Store is not selected.');
  }
  const res = await AxiosInstance.get(
    `Store/${storeId}/Checkout/CheckoutOccupancy`
  );

  return res;
}

export async function GetCheckoutOccupancies(storeID) {
  if (!storeID) {
    return Promise.reject('Store is not selected.');
  }
  const res = await AxiosInstance.get(
    `Store/${storeID}/Checkout/CheckoutWaitingOccupancy`
  );

  return res;
}

export async function GetMaxCheckoutVisitorCounts(params) {
  const { sdate, edate, storeID, stime, etime } = params;

  if (!storeID) {
    return Promise.reject('Store is not selected.');
  }

  const res = await AxiosInstance.get(
    `Store/${storeID}/Checkout/MaxCheckoutWaitingVisitorCount?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`
  );

  return res;
}

export async function deleteCampaigns(campaignID) {
  const res = await AxiosInstance.delete(`/Campaigns/${campaignID}`);
  return Promise.resolve(res);
}
export async function PostBreachSummaries(
  brandId,
  startDate,
  stime,
  endDate,
  etime,
  filter,
  StoreIds,
  DepartmentIds,
  Dwell,
  densityExceded
) {
  const res = await AxiosInstance.post(
    `/IETT/api/Breaches/Summaries?Storeid=${StoreIds}&Sdate=${startDate}&Edate=${endDate}&Filter=${
      filter === '0'
        ? '5'
        : filter === '1'
        ? '0'
        : filter === '2'
        ? '1'
        : filter === '3'
        ? '2'
        : filter
    }&Stime=${stime}&Etime=${etime}`,
    {
      storeIds: [StoreIds],
      departmentIds: DepartmentIds,
      dwell: Dwell,
      densityExceed: densityExceded
    },
    {
      headers: {
        'with-context': 1
      }
    }
  );
  return res;
}
export async function DownloadBreachSummaries(
  brandId,
  startDate,
  stime,
  endDate,
  etime,
  filter,
  StoreIds = [],
  DepartmentIds,
  Dwell,
  densityExceded
) {
  const res = await AxiosInstance.post(
    `/IETT/api/Breaches/DownloadSummaries?Storeid=${StoreIds[0]}&Sdate=${startDate}&Edate=${endDate}&Filter=${filter}&Stime=${stime}&Etime=${etime}`,
    {
      storeIds: StoreIds,
      departmentIds: DepartmentIds,
      dwell: Dwell,
      densityExceed: densityExceded
    },
    {
      responseType: 'blob',
      headers: {
        [IsFile]: '1'
      }
    }
  );
  downloadFile(res);
}

export async function getViolationReports() {
  const res = await AxiosInstance.get('/IETT/api/Report/Filters', {
    headers: {
      'with-context': 1
    }
  });
  return res;
}

export async function addViolationReport(report) {
  return AxiosInstance.post('/IETT/api/Report/Filter', report, {
    headers: {
      'with-context': 1
    }
  });
}

export async function deleteViolationReport(reportId) {
  return await AxiosInstance.delete(`/IETT/api/Report/Filter?id=${reportId}`, {
    headers: {
      'with-context': 1
    }
  });
}

export async function getReportSchedules() {
  const res = await AxiosInstance.get('/IETT/api/Report/Schedules', {
    headers: {
      'with-context': 1
    }
  });
  return res;
}

export async function addReportSchedule(report) {
  return AxiosInstance.post('/IETT/api/Report/Schedule', report, {
    headers: {
      'with-context': 1
    }
  });
}

export async function deleteReportSchedule(reportId) {
  return await AxiosInstance.delete(
    `/IETT/api/Report/Schedule?id=${reportId}`,
    {
      headers: {
        'with-context': 1
      }
    }
  );
}

export async function getIETTEmails() {
  const res = await AxiosInstance.get('/IETT/api/Report/Emails', {
    headers: {
      'with-context': 1
    }
  });
  return res;
}

export async function GetFieldSearches() {
  const res = await AxiosInstance.get('/Admin/FieldSearches');
  return res;
}

export async function ArchivePolyLine(cameraId, lineId, date) {
  return AxiosInstance.post(
    `/Camera/${cameraId}/ArchivePolyLine/${lineId}?date=${date}`
  );
}
export async function ArchivePolyCountLine(cameraId, lineId, date) {
  return AxiosInstance.post(
    `/Camera/${cameraId}/ArchiveLine/${lineId}?date=${date}`
  );
}
export async function InvalidateLineData(lineID, cameraId, date) {
  await AxiosInstance.post(
    `Camera/${cameraId}/InvalidateLineData/${lineID}`,
    date,
    {
      headers: {
        'Content-Type': 'text/plain'
      }
    }
  );
}
export async function InvalidateEntranceLineData(PolyLineID, cameraId, date) {
  await AxiosInstance.post(
    `Camera/${cameraId}/InvalidateEntranceLineData/${PolyLineID}`,
    date,
    {
      headers: {
        'Content-Type': 'text/plain'
      }
    }
  );
}
export async function BrandTable(p) {
  const { brandID, sdate, edate, stime, etime } = p;
  const res = await AxiosInstance.get(
    `/Brand/${brandID}/Table?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`
  );
  return res;
}
export async function GetBrandHomePagePhoto(brandId) {
  if (!brandId) return Promise.reject('You should select brand');

  const res = await AxiosInstance.get(`/Brand/${brandId}/HomePagePhoto`, {
    responseType: 'arraybuffer',
    headers: {
      [IsPhoto]: '1'
    }
  });

  const base64 = ImgToBase64(res);

  return Promise.resolve(base64);
}

export async function GetStoreSummary(params) {
  const { sdate, edate, storeID, stime, etime, filterType } = params;

  if (!storeID) {
    return Promise.reject('Store is not selected.');
  }

  const res = await getRequest({
    url: `/Store/${storeID}/traffic?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filterType}&tzoffset=${timeZoneOffset}`,
    requestName: 'GetStoreSummary'
  });

  return res;
}

export async function GetHourlyBusiestDistribution(params) {
  const { sdate, edate, storeID, stime, etime } = params;

  if (!storeID) {
    return Promise.reject('Store is not selected.');
  }

  const res = await AxiosInstance.get(
    `/Store/${storeID}/HourlyBusiestDistribbution?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`
  );

  return converDataToChartData(res);
}

export async function GetWeeklyComparisonEntrances(params) {
  const { sdate, edate, storeID, stime, etime } = params;

  if (!storeID) {
    return Promise.reject('Store is not selected.');
  }

  const res = await AxiosInstance.get(
    `/Store/${storeID}/WeeklyComparisonEntrances?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`
  );

  return converDataToChartData(res);
}

export async function GetBrandTableDistribution(params) {
  const {
    brandID,
    sdate,
    stime,
    edate,
    etime,
    filterType,
    storeIds,
    metricType,
    isTotal
  } = params;

  if (!brandID) {
    return Promise.reject('Brand is not selected.');
  }

  const storeIdsParam = convertStoreIdsToQueryParam(storeIds);

  if (!storeIdsParam) {
    return Promise.reject(apiErrorFormat('select.atleastOneStore'));
  }

  let url = `Brand/${brandID}/TableDistribution?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filterType}&tzoffset=${timeZoneOffset}&storeids=${storeIdsParam}&metrictype=${metricType}`;
  if (isTotal) {
    url += '&total=true';
  }
  const res = await AxiosInstance.get(url);

  return res;
}

export async function GetBrandTableDistributionWeekly(params) {
  const { brandID, sdate, stime, edate, etime, storeIds, metricType } = params;

  if (!brandID) {
    return Promise.reject('Brand is not selected.');
  }

  const storeIdsParam = convertStoreIdsToQueryParam(storeIds);

  if (!storeIdsParam) {
    return Promise.reject('You need to select at least 1 store.');
  }

  const res = await AxiosInstance.get(
    `Brand/${brandID}/TableDistribution?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=2&tzoffset=${timeZoneOffset}&storeids=${storeIdsParam}&metrictype=${metricType}`
  );

  return converDataToChartData(res);
}

export async function GetBrandTableDistributionWeeklyExcel(params) {
  const { brandID, sdate, stime, edate, etime, selectedStoreIds, metricType } =
    params;

  if (!brandID) {
    return Promise.reject('Brand is not selected.');
  }

  const storeIdsParam = convertStoreIdsToQueryParam(selectedStoreIds);

  if (!storeIdsParam) {
    return Promise.reject('You need to select at least 1 store.');
  }

  const res = await AxiosInstance.get(
    `Brand/${brandID}/TableDistribution?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=2&tzoffset=${timeZoneOffset}&storeids=${storeIdsParam}&metrictype=${metricType}`
  );

  return res;
}

export async function GetBrandTableDistributionPieChart(params) {
  const {
    globalBrandID,
    sdate,
    stime,
    edate,
    etime,
    allStoreIds,
    metricType,
    filterType
  } = params;

  if (!globalBrandID) {
    return Promise.reject('Brand is not selected.');
  }

  const storeIdsParam = convertStoreIdsToQueryParam(allStoreIds);

  if (!storeIdsParam) {
    return Promise.reject('Brand need to have at least 1 store.');
  }

  const res = await AxiosInstance.get(
    `Brand/${globalBrandID}/TableDistribution?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filterType}&tzoffset=${timeZoneOffset}&storeids=${storeIdsParam}&metrictype=${metricType}&total=true`
  );

  return converDataToChartData(res);
}

export async function GetBrandTableDistributionExcel(params, options) {
  const { filterType, isAllStores } = options;
  const {
    globalBrandID,
    sdate,
    stime,
    edate,
    etime,
    allStoreIds,
    selectedStoreIds,
    metricType
  } = params;

  if (!globalBrandID) {
    return Promise.reject('Brand is not selected.');
  }

  const storeIdsParam = convertStoreIdsToQueryParam(
    isAllStores ? allStoreIds : selectedStoreIds
  );

  if (!storeIdsParam) {
    return Promise.reject('Brand need to have at least 1 store.');
  }

  const res = await AxiosInstance.get(
    `Brand/${globalBrandID}/TableDistribution?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filterType}&tzoffset=${timeZoneOffset}&storeids=${storeIdsParam}&metrictype=${metricType}`
  );

  return res;
}
export async function CheckoutInvoiceSummary(p) {
  const { storeID, sdate, edate, filterType, stime, etime } = p;

  if (!storeID) {
    return Promise.reject('Store is not selected.');
  }

  const res = await AxiosInstance.get(
    `/Store/${storeID}/Checkout/CheckoutInvoiceSummary?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filterType}&tzoffset=${timeZoneOffset}`
  );
  return res;
}

export async function DownloadCheckoutDensityExcel(params) {
  const { sdate, edate, storeId, lang, filter, stime, etime } = params;
  const res = await AxiosInstance.get(
    `/Store/${storeId}/DownloadCheckoutDensity?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}&filter=${filter}`,
    {
      responseType: 'arraybuffer',
      headers: { 'Accept-Language': lang, [IsFile]: '1' }
    }
  );

  downloadFile(res);
}

export async function DownloadCheckoutCountsExcel(params) {
  const { sdate, edate, storeId, lang, filter, stime, etime } = params;

  if (!storeId) {
    return Promise.reject('Store is not selected.');
  }

  const res = await AxiosInstance.get(
    `/Store/${storeId}/DownloadCheckoutCounts?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}&filter=${filter}`,
    {
      responseType: 'arraybuffer',
      headers: { 'Accept-Language': lang, [IsFile]: '1' }
    }
  );

  downloadFile(res);
}

export async function GetPotentialLostCustomerCountDist(params) {
  const { sdate, edate, storeID, filterType, stime, etime } = params;

  if (!storeID) {
    return Promise.reject('Store is not selected.');
  }

  const res = await AxiosInstance.get(
    `/Store/${storeID}/Checkout/PotentialLostCustomerCountDistribution?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}&filter=${filterType}`
  );

  const error = checkError(res);

  if (error) {
    return Promise.reject(error);
  }

  const notificationItems = NotificationDataToChart(
    res?.find(item => item.Name === 'Nots')?.Serial
  );

  return converDataToChartData(res)?.map((item, i) => {
    delete item['Nots'];
    return {
      ...item,
      ...(notificationItems.at(i) ?? {})
    };
  });
}

export async function DownloadPotentialLostCustomerCountDist(params) {
  const { sdate, edate, storeId, lang, filter, stime, etime } = params;

  if (!storeId) {
    return Promise.reject('Store is not selected.');
  }

  const res = await AxiosInstance.get(
    `/Store/${storeId}/Checkout/DownloadPotentialLostCustomerCountDistribution?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}&filter=${filter}`,
    {
      responseType: 'arraybuffer',
      headers: { 'Accept-Language': lang, [IsFile]: '1' }
    }
  );

  downloadFile(res);
}

export async function GetCameraCurrentDataStatuses(storeID) {
  const res = await AxiosInstance.get(
    `/Store/${storeID}/CameraCurrentDataStatuses`
  );

  return res;
}

export async function LoadStoreEntranceExecutiveSummary(token) {
  const res = await AxiosInstance.get(
    `/AutoReports/LoadStoreEntranceExecutiveSummary?${token}`
  );

  return res;
}

export const Unsubscribe = params => {
  return AxiosInstance.get(`/User/Report/UnSubscribe${params}`);
};

export async function GetCheckouts(storeId) {
  const res = await AxiosInstance.get(`/Store/${storeId}/CashDeskCodes`);

  return res;
}

export async function InStoreAnalyticTraffic(token) {
  const res = await AxiosInstance.get(`/InStoreAnalytic/trafic?${token}`);

  return res;
}
export async function InStoreAnalyticEntranceSum(token) {
  const res = await AxiosInstance.get(
    `/InStoreAnalytic/EntranceSummary?${token}`
  );

  return res;
}
export async function InStoreAnalyticCheckoutSummary(token) {
  const res = await AxiosInstance.get(
    `/InStoreAnalytic/CheckoutSummary?${token}`
  );
  return res;
}

export async function DownloadInStoreAnalytic({ token, lang }) {
  const res = await AxiosInstance.get(`/InStoreAnalytic/Download?${token}`, {
    responseType: 'arraybuffer',
    headers: { 'Accept-Language': lang, [IsFile]: '1' }
  });

  downloadFile(res);
}
/**
 * @description Get sketch history by sketch id
 * @param {number} sketchId
 * @returns Sketch list
 */
export async function SketchHistory(sketchId, params) {
  return AxiosInstance.get(
    `/Sketch/${sketchId}/RectangleHistoryTimes?${
      params?.titledonly ? 'titledonly=1&' : ''
    }tzoffset=${timeZoneOffset}`
  );
}

/**
 * @descrition Get desired sketch history info (Drawings etc.)
 * @param {number} sketchId
 * @param {string} sketchHistoryId
 * @returns Sketch details
 */
export async function SketchHistoryDetails(sketchId, sketchHistoryId) {
  if (!sketchHistoryId) {
    return Promise.reject('Sketch  not found');
  }
  return AxiosInstance.get(
    `/Sketch/${sketchId}/RectangleHistories/${sketchHistoryId}?tzoffset=${timeZoneOffset}`
  );
}

export async function GetNotificationReceiverRules(p) {
  const { storeID } = p;

  if (!storeID) {
    return Promise.reject('You should select store');
  }

  const res = await AxiosInstance.get(`/Notification/${storeID}/Rules?type=1`);
  if (res === 0) {
    return Promise.reject('Üzgünüz hiç veri yok :(');
  }

  return Promise.resolve(res);
}
export async function GetNotificationUpdateStatus(ruleid, status) {
  const res = await AxiosInstance.post(
    `/Notification/Rule/${ruleid}/UpdateStatus/${status}`
  );
  return Promise.resolve(res);
}

export async function GetUserAutoReports() {
  const res = await AxiosInstance.get(
    `/user/AutoReports?tzOffset=${timeZoneOffset}`
  );
  if (res === 0) {
    return Promise.reject('Üzgünüz hiç veri yok :(');
  }
  return Promise.resolve(res);
}

export async function ReportSubscribe(reportId) {
  const res = await AxiosInstance.post(`/user/Report/Subscribe`, {
    ReportId: reportId
  });
  return res;
}
export async function ReportUnSubscribe(reportId) {
  const res = await AxiosInstance.post(`/user/Report/unSubscribe`, {
    ReportId: reportId
  });
  return res;
}

export async function DownloadStoreEntrance({ token, lang }) {
  const res = await AxiosInstance.get(
    `/AutoReports/StoreEntrance/Download?${token}`,
    {
      responseType: 'arraybuffer',
      headers: { 'Accept-Language': lang, [IsFile]: '1' }
    }
  );

  downloadFile(res);
}

export async function CashDeskSummaryReports(token) {
  const res = await AxiosInstance.get(`/AutoReports/CashDeskSummary?${token}`);

  return res;
}

export async function DownloadCheckoutSummary({ token, lang }) {
  const res = await AxiosInstance.get(
    `/AutoReports/CashdeskSummary/Download?${token}`,
    {
      responseType: 'arraybuffer',
      headers: { 'Accept-Language': lang, [IsFile]: '1' }
    }
  );

  downloadFile(res);
}

export async function BeaconSummaryReport(token) {
  const res = await AxiosInstance.get(`/AutoReports/BeaconSummary?${token}`);

  return res;
}

export async function DownloadBeaconSummary({ token, lang }) {
  const res = await AxiosInstance.get(
    `/AutoReports/BeaconSummary/Download?${token}`,
    {
      responseType: 'arraybuffer',
      headers: { 'Accept-Language': lang, [IsFile]: '1' }
    }
  );

  downloadFile(res);
}

export async function GetRegionCheckoutDistribution(
  brandId,
  sdate,
  edate,
  regionIds
) {
  if (!sdate) {
    return Promise.reject('Start date is not selected.');
  }

  if (!edate) {
    return Promise.reject('End date is not selected.');
  }

  if (!brandId) {
    return Promise.reject('Brand is not selected.');
  }

  if (!regionIds) {
    return Promise.reject('No region is not selected.');
  }

  const regionIdsString =
    Array.isArray(regionIds) && regionIds.length > 0
      ? '&regionids=' + regionIds.join(',')
      : '';

  const res = await AxiosInstance.get(
    `/Brand/${brandId}/CheckoutDistribution?sdate=${sdate}&edate=${edate}&tzoffset=${timeZoneOffset}&filter=8${regionIdsString}`
  );

  return res;
}

export async function GetStoreCheckoutDistribution(
  brandId,
  sdate,
  edate,
  storeIds
) {
  if (!sdate) {
    return Promise.reject('Start date is not selected.');
  }

  if (!edate) {
    return Promise.reject('End date is not selected.');
  }

  if (!brandId) {
    return Promise.reject('Brand is not selected.');
  }

  if (!storeIds) {
    return Promise.reject('No store is not selected.');
  }

  const storeIdsString =
    Array.isArray(storeIds) && storeIds.length > 0
      ? '&storeids=' + storeIds.join(',')
      : '';

  const res = await AxiosInstance.get(
    `/Brand/${brandId}/CheckoutDistribution?sdate=${sdate}&edate=${edate}&tzoffset=${timeZoneOffset}&filter=7${storeIdsString}`
  );

  return res;
}

export async function GetBrandRegions(brandId) {
  if (!brandId) {
    return Promise.reject('Brand is not selected.');
  }

  const res = await AxiosInstance.get(`/Brand/${brandId}/Regions`);

  return res;
}

export async function SetRectangleHistoryData(params) {
  const { sketchId, historyId, payload } = params;

  if (!sketchId) {
    return Promise.reject('Sketch is not selected.');
  }

  if (!historyId) {
    return Promise.reject('Sketch is not selected.');
  }

  const res = await AxiosInstance.post(
    `/Sketch/${sketchId}/RectangleHistories/${historyId}`,
    payload
  );

  return res;
}

export async function SketchHistories(params) {
  const { sketchId, sdate, edate } = params;

  if (!sketchId) {
    return Promise.reject('Sketch is not selected.');
  }

  if (!sdate) {
    return Promise.reject('Start date is not selected.');
  }

  if (!edate) {
    return Promise.reject('End date is not selected.');
  }

  const res = await AxiosInstance.get(
    `/Sketch/${sketchId}/Histories?sdate=${sdate}&edate=${edate}&tzoffset=${timeZoneOffset}`
  );

  return res;
}

export async function GetSketchRectangleHistoriesData(params) {
  const { sketchId, historyId, sdate, edate } = params;

  if (!sketchId) {
    return Promise.reject('Sketch is not selected.');
  }

  if (!historyId) {
    return Promise.reject('History is not selected.');
  }

  if (!sdate) {
    return Promise.reject('Start date is not selected.');
  }

  if (!edate) {
    return Promise.reject('End date is not selected.');
  }

  const res = await AxiosInstance.get(
    `/Sketch/${sketchId}/RectangleHistories/${historyId}/Data?sdate=${sdate}&edate=${edate}&tzoffset=${timeZoneOffset}`
  );

  return res;
}

export async function CreateComplexRule(payload) {
  const { storeId, ...restPayload } = payload;

  const res = await AxiosInstance.post(
    `/Notification/${storeId}/ComplexRule?tzoffset=${timeZoneOffset}`,
    restPayload
  );

  return res;
}

export async function GetComplexRules(storeId) {
  const res = await AxiosInstance.get(
    `/Notification/${storeId}/ComplexRules?tzoffset=${timeZoneOffset}`
  );

  return res;
}

export async function DeleteComplexRule(payload) {
  const { storeId, ruleId } = payload;

  const res = await AxiosInstance.delete(
    `/Notification/${storeId}/ComplexRule/${ruleId}?tzoffset=${timeZoneOffset}`
  );

  return res;
}

export async function ChangeComplexRuleStatus(payload) {
  const { storeId, ruleId, isPassive } = payload;

  const res = await AxiosInstance.post(
    `/Notification/${storeId}/ComplexRule/${ruleId}/UpdateStatus/${
      isPassive ? 1 : 0
    }`
  );

  return res;
}

export async function SetRectangleHistoryDate(params) {
  const { sketchId, historyId, payload } = params;

  if (!sketchId) {
    return Promise.reject('Sketch is not selected.');
  }

  if (!historyId) {
    return Promise.reject('Sketch is not selected.');
  }

  const res = await AxiosInstance.post(
    `/Sketch/${sketchId}/RectangleHistories/${historyId}?tzoffset=${timeZoneOffset}`,
    payload
  );

  return res;
}

export async function UpdateNotificationRule({ ruleId, data }) {
  const res = await AxiosInstance.post(
    `Notification/Rule/${ruleId}/Update`,
    data
  );

  return res;
}

export async function UpdateComplexNotificationRule({ ruleId, storeId, data }) {
  const res = await AxiosInstance.post(
    `Notification/${storeId}/ComplexRule/${ruleId}/Update`,
    data
  );

  return res;
}

export async function GetSketchLineEntrances(p) {
  const { storeID, sdate, edate, stime, etime, filterType } = p;

  if (!storeID) {
    return Promise.reject('Store is not selected.');
  }

  const res = await AxiosInstance.get(
    `/Store/${storeID}/GetSketchLineEntrances?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filterType}&tzoffset=${timeZoneOffset}`
  );

  return res;
}

export async function GetFloorLineMoveCounts(params) {
  const {
    brandID,
    storeID,
    sdate,
    edate,
    stime,
    etime,
    filterType,
    showAllBrand,
    sketchId
  } = params;
  if (!storeID) {
    return Promise.reject('You should select store');
  } else if (showAllBrand && !brandID) {
    return Promise.reject('You should select brand');
  }
  const res = await getRequest({
    url: `${showAllBrand ? 'Brand' : 'Store'}/${
      showAllBrand ? brandID : storeID
    }/LineMoveCounts?${showAllBrand ? `brandid=${brandID}&` : ''}${
      showAllBrand ? `storeid=${storeID}&` : ''
    }sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}&filter=${filterType}&lineType=${
      lineTypes.InFloorOrientation
    }${sketchId ? `&sketchId=${sketchId}` : ''}`,
    requestName: 'GetFloorLineMoveCounts'
  });

  if (res.length !== 0) return res;
  else return Promise.reject('No data available.');
}

export async function GetRegionCustomerServicesDistribution(
  brandId,
  sdate,
  edate,
  regionIds
) {
  if (!sdate) {
    return Promise.reject('Start date is not selected.');
  }

  if (!edate) {
    return Promise.reject('End date is not selected.');
  }

  if (!brandId) {
    return Promise.reject('Brand is not selected.');
  }

  if (!regionIds) {
    return Promise.reject('No region is not selected.');
  }

  const regionIdsString =
    Array.isArray(regionIds) && regionIds.length > 0
      ? '&regionids=' + regionIds.join(',')
      : '';

  const res = await AxiosInstance.get(
    `/Brand/${brandId}/CustomerServicesDistribution?sdate=${sdate}&edate=${edate}&tzoffset=${timeZoneOffset}&filter=8${regionIdsString}`
  );

  return res;
}

export async function GetStoreCustomerServicesDistribution(
  brandId,
  sdate,
  edate,
  storeIds
) {
  if (!sdate) {
    return Promise.reject('Start date is not selected.');
  }

  if (!edate) {
    return Promise.reject('End date is not selected.');
  }

  if (!brandId) {
    return Promise.reject('Brand is not selected.');
  }

  if (!storeIds) {
    return Promise.reject('No store is not selected.');
  }

  const storeIdsString =
    Array.isArray(storeIds) && storeIds.length > 0
      ? '&storeids=' + storeIds.join(',')
      : '';

  const res = await AxiosInstance.get(
    `/Brand/${brandId}/CustomerServicesDistribution?sdate=${sdate}&edate=${edate}&tzoffset=${timeZoneOffset}&filter=7${storeIdsString}`
  );

  return res;
}

export async function GetCustomerServicesDistributionForBrand(
  brandId,
  sdate,
  edate
) {
  if (!sdate) {
    return Promise.reject('Start date is not selected.');
  }

  if (!edate) {
    return Promise.reject('End date is not selected.');
  }

  if (!brandId) {
    return Promise.reject('Brand is not selected.');
  }

  const res = await AxiosInstance.get(
    `/Brand/${brandId}/CustomerServicesDistributionForBrand?sdate=${sdate}&edate=${edate}&tzoffset=${timeZoneOffset}`
  );

  return res;
}

export async function GetBrandCustomerServicesDistribution(
  brandId,
  sdate,
  edate
) {
  if (!sdate) {
    return Promise.reject('Start date is not selected.');
  }

  if (!edate) {
    return Promise.reject('End date is not selected.');
  }

  if (!brandId) {
    return Promise.reject('Brand is not selected.');
  }

  const res = await AxiosInstance.get(
    `/Brand/${brandId}/CustomerServicesDistributionForBrand?sdate=${sdate}&edate=${edate}&tzoffset=${timeZoneOffset}`
  );

  return res;
}

export async function GetBrandCheckoutDistribution(brandId, sdate, edate) {
  if (!sdate) {
    return Promise.reject('Start date is not selected.');
  }

  if (!edate) {
    return Promise.reject('End date is not selected.');
  }

  if (!brandId) {
    return Promise.reject('Brand is not selected.');
  }

  const res = await AxiosInstance.get(
    `/Brand/${brandId}/CheckoutDistributionForBrand?sdate=${sdate}&edate=${edate}&tzoffset=${timeZoneOffset}`
  );

  return res;
}

export async function CreateShopInShop(sketchID, payload) {
  if (!sketchID) {
    return Promise.reject('Sketch is not selected.');
  }
  const res = await AxiosInstance.post(
    `/Sketch/${sketchID}/CreateShopInShop`,
    payload
  );

  return res;
}

export async function GetShopInShops(params) {
  const { sketchID } = params;

  if (!sketchID) {
    return Promise.reject('Sketch is not selected.');
  }

  const res = await AxiosInstance.get(`/Sketch/${sketchID}/GetShopInShops`);

  return res;
}

export async function GetShopInShopCounts(params) {
  const { storeID, sdate, edate, stime, etime, filterType, rectID } = params;

  if (!storeID) {
    return Promise.reject('Store is not selected.');
  }

  if (!rectID) {
    return Promise.reject('Sketch rectangle is not selected.');
  }

  const res = await AxiosInstance.get(
    `/SketchRect/${rectID}/ShopInShopCounts?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${
      filterType === '0' ? '4' : filterType
    }&tzoffset=${timeZoneOffset}&storeid=${storeID}`
  );

  return res;
}

export async function GetShopInShopLinesCounts(params) {
  const { storeID, sdate, edate, stime, etime, filterType, rectID } = params;

  if (!storeID) {
    return Promise.reject('Store is not selected.');
  }

  if (!rectID) {
    return Promise.reject('Sketch rectangle is not selected.');
  }

  const res = await AxiosInstance.get(
    `/SketchRect/${rectID}/ShopInShopLinesCounts?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${
      filterType === '0' ? '4' : filterType
    }&tzoffset=${timeZoneOffset}&storeid=${storeID}`
  );

  return res;
}

export async function GetStoreShopInShopCounts(params) {
  const { storeID, sdate, edate, stime, etime, filterType } = params;

  if (!storeID) {
    return Promise.reject('Store is not selected.');
  }

  const res = await AxiosInstance.get(
    `/Store/${storeID}/ShopInShopCounts?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filterType}&tzoffset=${timeZoneOffset}&storeid=${storeID}`
  );

  return res;
}

export async function GetStoreNotificationsChart(p) {
  const { storeID, sdate, edate, stime, etime, filterType } = p;

  if (!storeID) {
    return Promise.reject('Store ID is required');
  }

  const res = await AxiosInstance.get(
    `/Store/${storeID}/ComplexNotifications?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filterType}&tzoffset=${timeZoneOffset}`
  );

  return NotificationDataToChart(res);
}

export async function GetStoresFirstStandDistribution(params) {
  const { brandID, sdate, edate, stime, etime } = params;

  if (!brandID || !sdate) {
    return Promise.reject('You should select both brand and start date');
  }
  const res = await getRequest({
    url: `/Brand/${brandID}/StoresFirstStandDistribution?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}`,
    requestName: 'StoresFirstStandDistribution'
  });

  if (res.pending) return res;

  return converDataToChartData(res);
}

export async function GetDepartmentsFirstStandDistribution(params) {
  const { globalBrandID, storeId, sdate, edate, stime, etime, metricType } =
    params;

  if (!globalBrandID) {
    return Promise.reject('Brand must be selected!');
  }

  if (!storeId) {
    return Promise.reject('Store must be selected!');
  }

  const res = await AxiosInstance.get(
    `/Brand/${globalBrandID}/DepartmentsFirstStandDistribution?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&tzoffset=${timeZoneOffset}&metricType=${metricType}&storeid=${storeId}`
  );

  return converDataToChartData(res);
}

export const UnsubscribePhoto = searchParams => {
  return AxiosInstance.get(`Camera/UnsubscribePhoto${searchParams}`);
};

export const DownloadLineEntranceSummary = async params => {
  const { storeID, sdate, edate, stime, etime, filterType, lang } = params;

  if (!storeID) {
    return Promise.reject('Store ID is required');
  }

  const res = await AxiosInstance.get(
    `/Store/${storeID}/DownloadLineEntranceSummary?sdate=${sdate}&edate=${edate}&stime=${stime}&etime=${etime}&filter=${filterType}&tzoffset=${timeZoneOffset}`,
    {
      responseType: 'arraybuffer',
      headers: { 'Accept-Language': lang, [IsFile]: '1' }
    }
  );

  downloadFile(res);
};

export async function GetBrandCurrencies(brandID) {
  if (!brandID) {
    return Promise.reject('Brand is not selected.');
  }

  const res = await AxiosInstance.get(`/Brands/${brandID}/currencies`);

  return res;
}
